/* This stylesheet is loaded last to handle any overrides from appworks or bootstrap */
@import "./authentication";
@import "./navbar";
@import "./block-ui";
@import "./../../../../node_modules/angular-calendar/css/angular-calendar.css";


/* Form Validation */
.form-group.required label::after,
.form-label.required::after,
span.required::after {
  content: "*";
  margin-left: 0.15em;
  margin-right: 0.25em;
  color: red;
}

.route-banner {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: purple;
  color: white;
}
.adminbar-spacer {
  height: 1.3rem;
}
.router-transitions{
  position: relative;
}
.top-bar-user-image {
  height: 30px;
  width: 30px;
  background-position: center;
  background-size: cover;
}

.actor-card-small .actor-profile-image {
  width: 50px;
  height: 50px;
}

.crs-ellipsis-link::after{
  content: "\f142";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;

  display: block;
  border: none;
  transform: none;
  margin: 0;
}

.profile-image-container {
  height: 50vw;
  width: 50vw;
  max-height: 275px;
  max-width: 275px;

  border-radius: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  margin: auto;
  border: solid 3px #ccc;
}
.profile-image-uploaded {
  width: 250px;
  height: auto;
  margin: auto;
  margin-bottom: 10px;
}

.view-type-toggle {
  padding: 10px 20px;
}