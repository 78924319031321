@import "./assets/styles/demo.css";

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

// Remove outline on desktops
@media(min-width: 720px) {

  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

// Fix horizontal scrollbar issue when .dropdown-menu-right element is shown
.layout-wrapper {
  overflow: hidden;
}

// *******************************************************************************
// * Page transition

.router-transitions {
  opacity: 1;
  transition: opacity .2s;

  body.app-loading & {
    opacity: .75;
  }
}

// *******************************************************************************
// * Animations

@include keyframes(ngFloatingElementAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

html {

  .tooltip,
  .popover {
    animation: ngFloatingElementAnimation .25s;
  }

  .dropdown-menu {
    animation: ngFloatingElementAnimation .1s;
  }
}

// *******************************************************************************
// * Modal animations

@include keyframes(ngModalBackdropAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .5;
  }
}

@include keyframes(ngDefaultModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(.8);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@include keyframes(ngTopModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(ngSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(ngFillInModalAnimation) {
  0% {
    opacity: 0;
    transform: scale(.5, .5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

ngb-modal-backdrop.modal-backdrop {
  opacity: .5;
  animation: ngModalBackdropAnimation .15s ease-out;
}

ngb-modal-window {
  &.modal .modal-dialog {
    animation: ngDefaultModalAnimation .15s ease-out;
  }

  &.modal-top .modal-dialog {
    animation: ngTopModalAnimation .15s ease-out;
  }

  &.modal-slide .modal-dialog {
    animation: ngSlideModalAnimation .15s ease-out;
  }

  &.modal-fill-in .modal-dialog {
    animation: ngFillInModalAnimation .15s ease-out;
  }


}


// *******************************************************************************
// * Accordion

ngb-accordion .card-header .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  border: 0;
  font-size: .894rem;
}

.ngb-accordion-with-icon .card-header .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

// *******************************************************************************
// * Typeahead

ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}

// * Sidenav Title
.layout-collapsed {
  .sidenav-title {
    display: none;
  }

  &.layout-sidenav-hover,
  &.layout-expanded {
    .sidenav-title {
      display: block;
    }
  }
}

.layout-qdup-navbar .sidenav-item {
  margin-right: 20px;
}

.qdup-menu-front .sidenav-link {
  color: #4c2774;
  font-weight:bold;
}
.qdup-menu-front .sidenav-link:hover {
  color: #DFACCF;
  font-weight: bold;
}


// ***********
// * Reel Carousel
ol.carousel-indicators {
  display: none !important;
}

.carousel a[role="button"] {
  top: inherit;
  background-color: #666;
  bottom: calc(100% - 40px);
  height: 40px;
}


.cal-hour-selected {
  background-color: #4c2774 !important;
  color: white;
}

#footer-confirm {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
#footer-confirm button {
  width: 100%;
  border-radius: 0px;
}