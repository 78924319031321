.text-twitter {
  color: #1da1f2 !important;
}

a.text-twitter:hover, a.text-twitter:focus {
  color: #0b76b8 !important;
}

.bg-twitter {
  background-color: #1da1f2 !important;
}

a.bg-twitter:hover, a.bg-twitter:focus {
  background-color: #1c99e6 !important;
}

.bg-twitter-dark {
  background-color: #1a91da !important;
}

a.bg-twitter-dark:hover, a.bg-twitter-dark:focus {
  background-color: #198acf !important;
}

.bg-twitter-darker {
  background-color: #1989ce !important;
}

a.bg-twitter-darker:hover, a.bg-twitter-darker:focus {
  background-color: #1882c4 !important;
}

.badge-outline-twitter {
  background-color: transparent;
  box-shadow: 0 0 0 1px #1da1f2 inset;
  color: #1da1f2;
}

.badge-outline-twitter[href]:hover, .badge-outline-twitter[href]:focus {
  color: #1da1f2;
  text-decoration: none;
}

.btn .badge-outline-twitter {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #1da1f2 inset !important;
  color: #1da1f2 !important;
}

.btn-twitter {
  border-color: transparent;
  background: #1da1f2;
  color: #fff;
}

.btn-twitter:hover {
  border-color: transparent;
  background: #1c99e6;
  color: #fff;
}

.btn-twitter:focus, .btn-twitter.focus {
  box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.4);
}

.btn-twitter.disabled, .btn-twitter:disabled {
  border-color: transparent !important;
  background: #1da1f2 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-twitter .badge {
  background: #fff;
  color: #1da1f2;
}

.btn-twitter:active,
.btn-twitter.active,
.show > .btn-twitter.dropdown-toggle {
  border-color: transparent;
  background: #198cd3;
  box-shadow: none;
}

.btn-group .btn-twitter,
.input-group-prepend .btn-twitter,
.input-group-append .btn-twitter {
  border-right: 1px solid #198cd3;
  border-left: 1px solid #198cd3;
}

.btn-outline-twitter {
  border-color: #1da1f2;
  background: transparent;
  color: #1da1f2;
}

.btn-outline-twitter:hover {
  border-color: transparent;
  background: #1da1f2;
  color: #fff;
}

.btn-outline-twitter:focus, .btn-outline-twitter.focus {
  box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.4);
}

.btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
  border-color: #1da1f2 !important;
  background: transparent !important;
  color: #1da1f2 !important;
}

.btn-outline-twitter:active,
.btn-outline-twitter.active,
.show > .btn-outline-twitter.dropdown-toggle {
  border-color: transparent;
  background: #1c99e6;
  color: #fff;
  box-shadow: none;
}

.btn-outline-twitter .badge {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-outline-twitter:hover .badge,
.btn-outline-twitter:focus .badge,
.btn-outline-twitter:active .badge,
.btn-outline-twitter.active .badge,
.show > .btn-outline-twitter.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #1da1f2;
}

.text-google {
  color: #d1523f !important;
}

a.text-google:hover, a.text-google:focus {
  color: #9e3526 !important;
}

.bg-google {
  background-color: #d1523f !important;
}

a.bg-google:hover, a.bg-google:focus {
  background-color: #c74e3c !important;
}

.bg-google-dark {
  background-color: #bc4a39 !important;
}

a.bg-google-dark:hover, a.bg-google-dark:focus {
  background-color: #b34636 !important;
}

.bg-google-darker {
  background-color: #b24636 !important;
}

a.bg-google-darker:hover, a.bg-google-darker:focus {
  background-color: #a94333 !important;
}

.badge-outline-google {
  background-color: transparent;
  box-shadow: 0 0 0 1px #d1523f inset;
  color: #d1523f;
}

.badge-outline-google[href]:hover, .badge-outline-google[href]:focus {
  color: #d1523f;
  text-decoration: none;
}

.btn .badge-outline-google {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #d1523f inset !important;
  color: #d1523f !important;
}

.btn-google {
  border-color: transparent;
  background: #d1523f;
  color: #fff;
}

.btn-google:hover {
  border-color: transparent;
  background: #c74e3c;
  color: #fff;
}

.btn-google:focus, .btn-google.focus {
  box-shadow: 0 0 0 2px rgba(209, 82, 63, 0.4);
}

.btn-google.disabled, .btn-google:disabled {
  border-color: transparent !important;
  background: #d1523f !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-google .badge {
  background: #fff;
  color: #d1523f;
}

.btn-google:active,
.btn-google.active,
.show > .btn-google.dropdown-toggle {
  border-color: transparent;
  background: #b64737;
  box-shadow: none;
}

.btn-group .btn-google,
.input-group-prepend .btn-google,
.input-group-append .btn-google {
  border-right: 1px solid #b64737;
  border-left: 1px solid #b64737;
}

.btn-outline-google {
  border-color: #d1523f;
  background: transparent;
  color: #d1523f;
}

.btn-outline-google:hover {
  border-color: transparent;
  background: #d1523f;
  color: #fff;
}

.btn-outline-google:focus, .btn-outline-google.focus {
  box-shadow: 0 0 0 2px rgba(209, 82, 63, 0.4);
}

.btn-outline-google.disabled, .btn-outline-google:disabled {
  border-color: #d1523f !important;
  background: transparent !important;
  color: #d1523f !important;
}

.btn-outline-google:active,
.btn-outline-google.active,
.show > .btn-outline-google.dropdown-toggle {
  border-color: transparent;
  background: #c74e3c;
  color: #fff;
  box-shadow: none;
}

.btn-outline-google .badge {
  background: #d1523f;
  border-color: #d1523f;
  color: #fff;
}

.btn-outline-google:hover .badge,
.btn-outline-google:focus .badge,
.btn-outline-google:active .badge,
.btn-outline-google.active .badge,
.show > .btn-outline-google.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #d1523f;
}

.text-facebook {
  color: #3b5998 !important;
}

a.text-facebook:hover, a.text-facebook:focus {
  color: #263961 !important;
}

.bg-facebook {
  background-color: #3b5998 !important;
}

a.bg-facebook:hover, a.bg-facebook:focus {
  background-color: #385590 !important;
}

.bg-facebook-dark {
  background-color: #355089 !important;
}

a.bg-facebook-dark:hover, a.bg-facebook-dark:focus {
  background-color: #324c82 !important;
}

.bg-facebook-darker {
  background-color: #324c81 !important;
}

a.bg-facebook-darker:hover, a.bg-facebook-darker:focus {
  background-color: #30487b !important;
}

.badge-outline-facebook {
  background-color: transparent;
  box-shadow: 0 0 0 1px #3b5998 inset;
  color: #3b5998;
}

.badge-outline-facebook[href]:hover, .badge-outline-facebook[href]:focus {
  color: #3b5998;
  text-decoration: none;
}

.btn .badge-outline-facebook {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #3b5998 inset !important;
  color: #3b5998 !important;
}

.btn-facebook {
  border-color: transparent;
  background: #3b5998;
  color: #fff;
}

.btn-facebook:hover {
  border-color: transparent;
  background: #385590;
  color: #fff;
}

.btn-facebook:focus, .btn-facebook.focus {
  box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.4);
}

.btn-facebook.disabled, .btn-facebook:disabled {
  border-color: transparent !important;
  background: #3b5998 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-facebook .badge {
  background: #fff;
  color: #3b5998;
}

.btn-facebook:active,
.btn-facebook.active,
.show > .btn-facebook.dropdown-toggle {
  border-color: transparent;
  background: #334d84;
  box-shadow: none;
}

.btn-group .btn-facebook,
.input-group-prepend .btn-facebook,
.input-group-append .btn-facebook {
  border-right: 1px solid #334d84;
  border-left: 1px solid #334d84;
}

.btn-outline-facebook {
  border-color: #3b5998;
  background: transparent;
  color: #3b5998;
}

.btn-outline-facebook:hover {
  border-color: transparent;
  background: #3b5998;
  color: #fff;
}

.btn-outline-facebook:focus, .btn-outline-facebook.focus {
  box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.4);
}

.btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
  border-color: #3b5998 !important;
  background: transparent !important;
  color: #3b5998 !important;
}

.btn-outline-facebook:active,
.btn-outline-facebook.active,
.show > .btn-outline-facebook.dropdown-toggle {
  border-color: transparent;
  background: #385590;
  color: #fff;
  box-shadow: none;
}

.btn-outline-facebook .badge {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-outline-facebook:hover .badge,
.btn-outline-facebook:focus .badge,
.btn-outline-facebook:active .badge,
.btn-outline-facebook.active .badge,
.show > .btn-outline-facebook.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #3b5998;
}

.text-pinterest {
  color: #bd121c !important;
}

a.text-pinterest:hover, a.text-pinterest:focus {
  color: #770b12 !important;
}

.bg-pinterest {
  background-color: #bd121c !important;
}

a.bg-pinterest:hover, a.bg-pinterest:focus {
  background-color: #b4111b !important;
}

.bg-pinterest-dark {
  background-color: #aa1019 !important;
}

a.bg-pinterest-dark:hover, a.bg-pinterest-dark:focus {
  background-color: #a20f18 !important;
}

.bg-pinterest-darker {
  background-color: #a10f18 !important;
}

a.bg-pinterest-darker:hover, a.bg-pinterest-darker:focus {
  background-color: #990e17 !important;
}

.badge-outline-pinterest {
  background-color: transparent;
  box-shadow: 0 0 0 1px #bd121c inset;
  color: #bd121c;
}

.badge-outline-pinterest[href]:hover, .badge-outline-pinterest[href]:focus {
  color: #bd121c;
  text-decoration: none;
}

.btn .badge-outline-pinterest {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #bd121c inset !important;
  color: #bd121c !important;
}

.btn-pinterest {
  border-color: transparent;
  background: #bd121c;
  color: #fff;
}

.btn-pinterest:hover {
  border-color: transparent;
  background: #b4111b;
  color: #fff;
}

.btn-pinterest:focus, .btn-pinterest.focus {
  box-shadow: 0 0 0 2px rgba(189, 18, 28, 0.4);
}

.btn-pinterest.disabled, .btn-pinterest:disabled {
  border-color: transparent !important;
  background: #bd121c !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-pinterest .badge {
  background: #fff;
  color: #bd121c;
}

.btn-pinterest:active,
.btn-pinterest.active,
.show > .btn-pinterest.dropdown-toggle {
  border-color: transparent;
  background: #a41018;
  box-shadow: none;
}

.btn-group .btn-pinterest,
.input-group-prepend .btn-pinterest,
.input-group-append .btn-pinterest {
  border-right: 1px solid #a41018;
  border-left: 1px solid #a41018;
}

.btn-outline-pinterest {
  border-color: #bd121c;
  background: transparent;
  color: #bd121c;
}

.btn-outline-pinterest:hover {
  border-color: transparent;
  background: #bd121c;
  color: #fff;
}

.btn-outline-pinterest:focus, .btn-outline-pinterest.focus {
  box-shadow: 0 0 0 2px rgba(189, 18, 28, 0.4);
}

.btn-outline-pinterest.disabled, .btn-outline-pinterest:disabled {
  border-color: #bd121c !important;
  background: transparent !important;
  color: #bd121c !important;
}

.btn-outline-pinterest:active,
.btn-outline-pinterest.active,
.show > .btn-outline-pinterest.dropdown-toggle {
  border-color: transparent;
  background: #b4111b;
  color: #fff;
  box-shadow: none;
}

.btn-outline-pinterest .badge {
  background: #bd121c;
  border-color: #bd121c;
  color: #fff;
}

.btn-outline-pinterest:hover .badge,
.btn-outline-pinterest:focus .badge,
.btn-outline-pinterest:active .badge,
.btn-outline-pinterest.active .badge,
.show > .btn-outline-pinterest.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #bd121c;
}

.text-instagram {
  color: #000 !important;
}

a.text-instagram:hover, a.text-instagram:focus {
  color: black !important;
}

.bg-instagram {
  background-color: #000 !important;
}

a.bg-instagram:hover, a.bg-instagram:focus {
  background-color: black !important;
}

.bg-instagram-dark {
  background-color: black !important;
}

a.bg-instagram-dark:hover, a.bg-instagram-dark:focus {
  background-color: black !important;
}

.bg-instagram-darker {
  background-color: black !important;
}

a.bg-instagram-darker:hover, a.bg-instagram-darker:focus {
  background-color: black !important;
}

.badge-outline-instagram {
  background-color: transparent;
  box-shadow: 0 0 0 1px #000 inset;
  color: #000;
}

.badge-outline-instagram[href]:hover, .badge-outline-instagram[href]:focus {
  color: #000;
  text-decoration: none;
}

.btn .badge-outline-instagram {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #000 inset !important;
  color: #000 !important;
}

.btn-instagram {
  border-color: transparent;
  background: #000;
  color: #fff;
}

.btn-instagram:hover {
  border-color: transparent;
  background: black;
  color: #fff;
}

.btn-instagram:focus, .btn-instagram.focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
}

.btn-instagram.disabled, .btn-instagram:disabled {
  border-color: transparent !important;
  background: #000 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-instagram .badge {
  background: #fff;
  color: #000;
}

.btn-instagram:active,
.btn-instagram.active,
.show > .btn-instagram.dropdown-toggle {
  border-color: transparent;
  background: black;
  box-shadow: none;
}

.btn-group .btn-instagram,
.input-group-prepend .btn-instagram,
.input-group-append .btn-instagram {
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.btn-outline-instagram {
  border-color: #000;
  background: transparent;
  color: #000;
}

.btn-outline-instagram:hover {
  border-color: transparent;
  background: #000;
  color: #fff;
}

.btn-outline-instagram:focus, .btn-outline-instagram.focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
}

.btn-outline-instagram.disabled, .btn-outline-instagram:disabled {
  border-color: #000 !important;
  background: transparent !important;
  color: #000 !important;
}

.btn-outline-instagram:active,
.btn-outline-instagram.active,
.show > .btn-outline-instagram.dropdown-toggle {
  border-color: transparent;
  background: black;
  color: #fff;
  box-shadow: none;
}

.btn-outline-instagram .badge {
  background: #000;
  border-color: #000;
  color: #fff;
}

.btn-outline-instagram:hover .badge,
.btn-outline-instagram:focus .badge,
.btn-outline-instagram:active .badge,
.btn-outline-instagram.active .badge,
.show > .btn-outline-instagram.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #000;
}

.text-flickr {
  color: #ff0084 !important;
}

a.text-flickr:hover, a.text-flickr:focus {
  color: #b3005c !important;
}

.bg-flickr {
  background-color: #ff0084 !important;
}

a.bg-flickr:hover, a.bg-flickr:focus {
  background-color: #f2007d !important;
}

.bg-flickr-dark {
  background-color: #e60077 !important;
}

a.bg-flickr-dark:hover, a.bg-flickr-dark:focus {
  background-color: #db0071 !important;
}

.bg-flickr-darker {
  background-color: #d90070 !important;
}

a.bg-flickr-darker:hover, a.bg-flickr-darker:focus {
  background-color: #ce006a !important;
}

.badge-outline-flickr {
  background-color: transparent;
  box-shadow: 0 0 0 1px #ff0084 inset;
  color: #ff0084;
}

.badge-outline-flickr[href]:hover, .badge-outline-flickr[href]:focus {
  color: #ff0084;
  text-decoration: none;
}

.btn .badge-outline-flickr {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #ff0084 inset !important;
  color: #ff0084 !important;
}

.btn-flickr {
  border-color: transparent;
  background: #ff0084;
  color: #fff;
}

.btn-flickr:hover {
  border-color: transparent;
  background: #f2007d;
  color: #fff;
}

.btn-flickr:focus, .btn-flickr.focus {
  box-shadow: 0 0 0 2px rgba(255, 0, 132, 0.4);
}

.btn-flickr.disabled, .btn-flickr:disabled {
  border-color: transparent !important;
  background: #ff0084 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-flickr .badge {
  background: #fff;
  color: #ff0084;
}

.btn-flickr:active,
.btn-flickr.active,
.show > .btn-flickr.dropdown-toggle {
  border-color: transparent;
  background: #de0073;
  box-shadow: none;
}

.btn-group .btn-flickr,
.input-group-prepend .btn-flickr,
.input-group-append .btn-flickr {
  border-right: 1px solid #de0073;
  border-left: 1px solid #de0073;
}

.btn-outline-flickr {
  border-color: #ff0084;
  background: transparent;
  color: #ff0084;
}

.btn-outline-flickr:hover {
  border-color: transparent;
  background: #ff0084;
  color: #fff;
}

.btn-outline-flickr:focus, .btn-outline-flickr.focus {
  box-shadow: 0 0 0 2px rgba(255, 0, 132, 0.4);
}

.btn-outline-flickr.disabled, .btn-outline-flickr:disabled {
  border-color: #ff0084 !important;
  background: transparent !important;
  color: #ff0084 !important;
}

.btn-outline-flickr:active,
.btn-outline-flickr.active,
.show > .btn-outline-flickr.dropdown-toggle {
  border-color: transparent;
  background: #f2007d;
  color: #fff;
  box-shadow: none;
}

.btn-outline-flickr .badge {
  background: #ff0084;
  border-color: #ff0084;
  color: #fff;
}

.btn-outline-flickr:hover .badge,
.btn-outline-flickr:focus .badge,
.btn-outline-flickr:active .badge,
.btn-outline-flickr.active .badge,
.show > .btn-outline-flickr.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #ff0084;
}

.text-linkedin {
  color: #0077b5 !important;
}

a.text-linkedin:hover, a.text-linkedin:focus {
  color: #004569 !important;
}

.bg-linkedin {
  background-color: #0077b5 !important;
}

a.bg-linkedin:hover, a.bg-linkedin:focus {
  background-color: #0071ac !important;
}

.bg-linkedin-dark {
  background-color: #006ba3 !important;
}

a.bg-linkedin-dark:hover, a.bg-linkedin-dark:focus {
  background-color: #00669b !important;
}

.bg-linkedin-darker {
  background-color: #00659a !important;
}

a.bg-linkedin-darker:hover, a.bg-linkedin-darker:focus {
  background-color: #006092 !important;
}

.badge-outline-linkedin {
  background-color: transparent;
  box-shadow: 0 0 0 1px #0077b5 inset;
  color: #0077b5;
}

.badge-outline-linkedin[href]:hover, .badge-outline-linkedin[href]:focus {
  color: #0077b5;
  text-decoration: none;
}

.btn .badge-outline-linkedin {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #0077b5 inset !important;
  color: #0077b5 !important;
}

.btn-linkedin {
  border-color: transparent;
  background: #0077b5;
  color: #fff;
}

.btn-linkedin:hover {
  border-color: transparent;
  background: #0071ac;
  color: #fff;
}

.btn-linkedin:focus, .btn-linkedin.focus {
  box-shadow: 0 0 0 2px rgba(0, 119, 181, 0.4);
}

.btn-linkedin.disabled, .btn-linkedin:disabled {
  border-color: transparent !important;
  background: #0077b5 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-linkedin .badge {
  background: #fff;
  color: #0077b5;
}

.btn-linkedin:active,
.btn-linkedin.active,
.show > .btn-linkedin.dropdown-toggle {
  border-color: transparent;
  background: #00689d;
  box-shadow: none;
}

.btn-group .btn-linkedin,
.input-group-prepend .btn-linkedin,
.input-group-append .btn-linkedin {
  border-right: 1px solid #00689d;
  border-left: 1px solid #00689d;
}

.btn-outline-linkedin {
  border-color: #0077b5;
  background: transparent;
  color: #0077b5;
}

.btn-outline-linkedin:hover {
  border-color: transparent;
  background: #0077b5;
  color: #fff;
}

.btn-outline-linkedin:focus, .btn-outline-linkedin.focus {
  box-shadow: 0 0 0 2px rgba(0, 119, 181, 0.4);
}

.btn-outline-linkedin.disabled, .btn-outline-linkedin:disabled {
  border-color: #0077b5 !important;
  background: transparent !important;
  color: #0077b5 !important;
}

.btn-outline-linkedin:active,
.btn-outline-linkedin.active,
.show > .btn-outline-linkedin.dropdown-toggle {
  border-color: transparent;
  background: #0071ac;
  color: #fff;
  box-shadow: none;
}

.btn-outline-linkedin .badge {
  background: #0077b5;
  border-color: #0077b5;
  color: #fff;
}

.btn-outline-linkedin:hover .badge,
.btn-outline-linkedin:focus .badge,
.btn-outline-linkedin:active .badge,
.btn-outline-linkedin.active .badge,
.show > .btn-outline-linkedin.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #0077b5;
}

.text-periscope {
  color: #40a4c4 !important;
}

a.text-periscope:hover, a.text-periscope:focus {
  color: #2b758c !important;
}

.bg-periscope {
  background-color: #40a4c4 !important;
}

a.bg-periscope:hover, a.bg-periscope:focus {
  background-color: #3d9cba !important;
}

.bg-periscope-dark {
  background-color: #3a94b0 !important;
}

a.bg-periscope-dark:hover, a.bg-periscope-dark:focus {
  background-color: #378da7 !important;
}

.bg-periscope-darker {
  background-color: #368ba7 !important;
}

a.bg-periscope-darker:hover, a.bg-periscope-darker:focus {
  background-color: #33849f !important;
}

.badge-outline-periscope {
  background-color: transparent;
  box-shadow: 0 0 0 1px #40a4c4 inset;
  color: #40a4c4;
}

.badge-outline-periscope[href]:hover, .badge-outline-periscope[href]:focus {
  color: #40a4c4;
  text-decoration: none;
}

.btn .badge-outline-periscope {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #40a4c4 inset !important;
  color: #40a4c4 !important;
}

.btn-periscope {
  border-color: transparent;
  background: #40a4c4;
  color: #fff;
}

.btn-periscope:hover {
  border-color: transparent;
  background: #3d9cba;
  color: #fff;
}

.btn-periscope:focus, .btn-periscope.focus {
  box-shadow: 0 0 0 2px rgba(64, 164, 196, 0.4);
}

.btn-periscope.disabled, .btn-periscope:disabled {
  border-color: transparent !important;
  background: #40a4c4 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-periscope .badge {
  background: #fff;
  color: #40a4c4;
}

.btn-periscope:active,
.btn-periscope.active,
.show > .btn-periscope.dropdown-toggle {
  border-color: transparent;
  background: #388fab;
  box-shadow: none;
}

.btn-group .btn-periscope,
.input-group-prepend .btn-periscope,
.input-group-append .btn-periscope {
  border-right: 1px solid #388fab;
  border-left: 1px solid #388fab;
}

.btn-outline-periscope {
  border-color: #40a4c4;
  background: transparent;
  color: #40a4c4;
}

.btn-outline-periscope:hover {
  border-color: transparent;
  background: #40a4c4;
  color: #fff;
}

.btn-outline-periscope:focus, .btn-outline-periscope.focus {
  box-shadow: 0 0 0 2px rgba(64, 164, 196, 0.4);
}

.btn-outline-periscope.disabled, .btn-outline-periscope:disabled {
  border-color: #40a4c4 !important;
  background: transparent !important;
  color: #40a4c4 !important;
}

.btn-outline-periscope:active,
.btn-outline-periscope.active,
.show > .btn-outline-periscope.dropdown-toggle {
  border-color: transparent;
  background: #3d9cba;
  color: #fff;
  box-shadow: none;
}

.btn-outline-periscope .badge {
  background: #40a4c4;
  border-color: #40a4c4;
  color: #fff;
}

.btn-outline-periscope:hover .badge,
.btn-outline-periscope:focus .badge,
.btn-outline-periscope:active .badge,
.btn-outline-periscope.active .badge,
.show > .btn-outline-periscope.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #40a4c4;
}

.text-tumblr {
  color: #35465c !important;
}

a.text-tumblr:hover, a.text-tumblr:focus {
  color: #19212b !important;
}

.bg-tumblr {
  background-color: #35465c !important;
}

a.bg-tumblr:hover, a.bg-tumblr:focus {
  background-color: #324357 !important;
}

.bg-tumblr-dark {
  background-color: #303f53 !important;
}

a.bg-tumblr-dark:hover, a.bg-tumblr-dark:focus {
  background-color: #2e3c4f !important;
}

.bg-tumblr-darker {
  background-color: #2d3c4e !important;
}

a.bg-tumblr-darker:hover, a.bg-tumblr-darker:focus {
  background-color: #2b394a !important;
}

.badge-outline-tumblr {
  background-color: transparent;
  box-shadow: 0 0 0 1px #35465c inset;
  color: #35465c;
}

.badge-outline-tumblr[href]:hover, .badge-outline-tumblr[href]:focus {
  color: #35465c;
  text-decoration: none;
}

.btn .badge-outline-tumblr {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #35465c inset !important;
  color: #35465c !important;
}

.btn-tumblr {
  border-color: transparent;
  background: #35465c;
  color: #fff;
}

.btn-tumblr:hover {
  border-color: transparent;
  background: #324357;
  color: #fff;
}

.btn-tumblr:focus, .btn-tumblr.focus {
  box-shadow: 0 0 0 2px rgba(53, 70, 92, 0.4);
}

.btn-tumblr.disabled, .btn-tumblr:disabled {
  border-color: transparent !important;
  background: #35465c !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-tumblr .badge {
  background: #fff;
  color: #35465c;
}

.btn-tumblr:active,
.btn-tumblr.active,
.show > .btn-tumblr.dropdown-toggle {
  border-color: transparent;
  background: #2e3d50;
  box-shadow: none;
}

.btn-group .btn-tumblr,
.input-group-prepend .btn-tumblr,
.input-group-append .btn-tumblr {
  border-right: 1px solid #2e3d50;
  border-left: 1px solid #2e3d50;
}

.btn-outline-tumblr {
  border-color: #35465c;
  background: transparent;
  color: #35465c;
}

.btn-outline-tumblr:hover {
  border-color: transparent;
  background: #35465c;
  color: #fff;
}

.btn-outline-tumblr:focus, .btn-outline-tumblr.focus {
  box-shadow: 0 0 0 2px rgba(53, 70, 92, 0.4);
}

.btn-outline-tumblr.disabled, .btn-outline-tumblr:disabled {
  border-color: #35465c !important;
  background: transparent !important;
  color: #35465c !important;
}

.btn-outline-tumblr:active,
.btn-outline-tumblr.active,
.show > .btn-outline-tumblr.dropdown-toggle {
  border-color: transparent;
  background: #324357;
  color: #fff;
  box-shadow: none;
}

.btn-outline-tumblr .badge {
  background: #35465c;
  border-color: #35465c;
  color: #fff;
}

.btn-outline-tumblr:hover .badge,
.btn-outline-tumblr:focus .badge,
.btn-outline-tumblr:active .badge,
.btn-outline-tumblr.active .badge,
.show > .btn-outline-tumblr.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #35465c;
}

.text-vimeo {
  color: #1ab7ae !important;
}

a.text-vimeo:hover, a.text-vimeo:focus {
  color: #10746e !important;
}

.bg-vimeo {
  background-color: #1ab7ae !important;
}

a.bg-vimeo:hover, a.bg-vimeo:focus {
  background-color: #19aea5 !important;
}

.bg-vimeo-dark {
  background-color: #17a59d !important;
}

a.bg-vimeo-dark:hover, a.bg-vimeo-dark:focus {
  background-color: #169d95 !important;
}

.bg-vimeo-darker {
  background-color: #169c94 !important;
}

a.bg-vimeo-darker:hover, a.bg-vimeo-darker:focus {
  background-color: #15948d !important;
}

.badge-outline-vimeo {
  background-color: transparent;
  box-shadow: 0 0 0 1px #1ab7ae inset;
  color: #1ab7ae;
}

.badge-outline-vimeo[href]:hover, .badge-outline-vimeo[href]:focus {
  color: #1ab7ae;
  text-decoration: none;
}

.btn .badge-outline-vimeo {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #1ab7ae inset !important;
  color: #1ab7ae !important;
}

.btn-vimeo {
  border-color: transparent;
  background: #1ab7ae;
  color: #fff;
}

.btn-vimeo:hover {
  border-color: transparent;
  background: #19aea5;
  color: #fff;
}

.btn-vimeo:focus, .btn-vimeo.focus {
  box-shadow: 0 0 0 2px rgba(26, 183, 174, 0.4);
}

.btn-vimeo.disabled, .btn-vimeo:disabled {
  border-color: transparent !important;
  background: #1ab7ae !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-vimeo .badge {
  background: #fff;
  color: #1ab7ae;
}

.btn-vimeo:active,
.btn-vimeo.active,
.show > .btn-vimeo.dropdown-toggle {
  border-color: transparent;
  background: #179f97;
  box-shadow: none;
}

.btn-group .btn-vimeo,
.input-group-prepend .btn-vimeo,
.input-group-append .btn-vimeo {
  border-right: 1px solid #179f97;
  border-left: 1px solid #179f97;
}

.btn-outline-vimeo {
  border-color: #1ab7ae;
  background: transparent;
  color: #1ab7ae;
}

.btn-outline-vimeo:hover {
  border-color: transparent;
  background: #1ab7ae;
  color: #fff;
}

.btn-outline-vimeo:focus, .btn-outline-vimeo.focus {
  box-shadow: 0 0 0 2px rgba(26, 183, 174, 0.4);
}

.btn-outline-vimeo.disabled, .btn-outline-vimeo:disabled {
  border-color: #1ab7ae !important;
  background: transparent !important;
  color: #1ab7ae !important;
}

.btn-outline-vimeo:active,
.btn-outline-vimeo.active,
.show > .btn-outline-vimeo.dropdown-toggle {
  border-color: transparent;
  background: #19aea5;
  color: #fff;
  box-shadow: none;
}

.btn-outline-vimeo .badge {
  background: #1ab7ae;
  border-color: #1ab7ae;
  color: #fff;
}

.btn-outline-vimeo:hover .badge,
.btn-outline-vimeo:focus .badge,
.btn-outline-vimeo:active .badge,
.btn-outline-vimeo.active .badge,
.show > .btn-outline-vimeo.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #1ab7ae;
}

.text-youtube {
  color: #b31217 !important;
}

a.text-youtube:hover, a.text-youtube:focus {
  color: #6d0b0e !important;
}

.bg-youtube {
  background-color: #b31217 !important;
}

a.bg-youtube:hover, a.bg-youtube:focus {
  background-color: #aa1116 !important;
}

.bg-youtube-dark {
  background-color: #a11015 !important;
}

a.bg-youtube-dark:hover, a.bg-youtube-dark:focus {
  background-color: #990f14 !important;
}

.bg-youtube-darker {
  background-color: #980f14 !important;
}

a.bg-youtube-darker:hover, a.bg-youtube-darker:focus {
  background-color: #900e13 !important;
}

.badge-outline-youtube {
  background-color: transparent;
  box-shadow: 0 0 0 1px #b31217 inset;
  color: #b31217;
}

.badge-outline-youtube[href]:hover, .badge-outline-youtube[href]:focus {
  color: #b31217;
  text-decoration: none;
}

.btn .badge-outline-youtube {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #b31217 inset !important;
  color: #b31217 !important;
}

.btn-youtube {
  border-color: transparent;
  background: #b31217;
  color: #fff;
}

.btn-youtube:hover {
  border-color: transparent;
  background: #aa1116;
  color: #fff;
}

.btn-youtube:focus, .btn-youtube.focus {
  box-shadow: 0 0 0 2px rgba(179, 18, 23, 0.4);
}

.btn-youtube.disabled, .btn-youtube:disabled {
  border-color: transparent !important;
  background: #b31217 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-youtube .badge {
  background: #fff;
  color: #b31217;
}

.btn-youtube:active,
.btn-youtube.active,
.show > .btn-youtube.dropdown-toggle {
  border-color: transparent;
  background: #9c1014;
  box-shadow: none;
}

.btn-group .btn-youtube,
.input-group-prepend .btn-youtube,
.input-group-append .btn-youtube {
  border-right: 1px solid #9c1014;
  border-left: 1px solid #9c1014;
}

.btn-outline-youtube {
  border-color: #b31217;
  background: transparent;
  color: #b31217;
}

.btn-outline-youtube:hover {
  border-color: transparent;
  background: #b31217;
  color: #fff;
}

.btn-outline-youtube:focus, .btn-outline-youtube.focus {
  box-shadow: 0 0 0 2px rgba(179, 18, 23, 0.4);
}

.btn-outline-youtube.disabled, .btn-outline-youtube:disabled {
  border-color: #b31217 !important;
  background: transparent !important;
  color: #b31217 !important;
}

.btn-outline-youtube:active,
.btn-outline-youtube.active,
.show > .btn-outline-youtube.dropdown-toggle {
  border-color: transparent;
  background: #aa1116;
  color: #fff;
  box-shadow: none;
}

.btn-outline-youtube .badge {
  background: #b31217;
  border-color: #b31217;
  color: #fff;
}

.btn-outline-youtube:hover .badge,
.btn-outline-youtube:focus .badge,
.btn-outline-youtube:active .badge,
.btn-outline-youtube.active .badge,
.show > .btn-outline-youtube.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #b31217;
}

.text-windows {
  color: #26aae1 !important;
}

a.text-windows:hover, a.text-windows:focus {
  color: #177aa4 !important;
}

.bg-windows {
  background-color: #26aae1 !important;
}

a.bg-windows:hover, a.bg-windows:focus {
  background-color: #24a2d6 !important;
}

.bg-windows-dark {
  background-color: #2299cb !important;
}

a.bg-windows-dark:hover, a.bg-windows-dark:focus {
  background-color: #2091c1 !important;
}

.bg-windows-darker {
  background-color: #2091bf !important;
}

a.bg-windows-darker:hover, a.bg-windows-darker:focus {
  background-color: #1e8ab5 !important;
}

.badge-outline-windows {
  background-color: transparent;
  box-shadow: 0 0 0 1px #26aae1 inset;
  color: #26aae1;
}

.badge-outline-windows[href]:hover, .badge-outline-windows[href]:focus {
  color: #26aae1;
  text-decoration: none;
}

.btn .badge-outline-windows {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #26aae1 inset !important;
  color: #26aae1 !important;
}

.btn-windows {
  border-color: transparent;
  background: #26aae1;
  color: #fff;
}

.btn-windows:hover {
  border-color: transparent;
  background: #24a2d6;
  color: #fff;
}

.btn-windows:focus, .btn-windows.focus {
  box-shadow: 0 0 0 2px rgba(38, 170, 225, 0.4);
}

.btn-windows.disabled, .btn-windows:disabled {
  border-color: transparent !important;
  background: #26aae1 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-windows .badge {
  background: #fff;
  color: #26aae1;
}

.btn-windows:active,
.btn-windows.active,
.show > .btn-windows.dropdown-toggle {
  border-color: transparent;
  background: #2194c4;
  box-shadow: none;
}

.btn-group .btn-windows,
.input-group-prepend .btn-windows,
.input-group-append .btn-windows {
  border-right: 1px solid #2194c4;
  border-left: 1px solid #2194c4;
}

.btn-outline-windows {
  border-color: #26aae1;
  background: transparent;
  color: #26aae1;
}

.btn-outline-windows:hover {
  border-color: transparent;
  background: #26aae1;
  color: #fff;
}

.btn-outline-windows:focus, .btn-outline-windows.focus {
  box-shadow: 0 0 0 2px rgba(38, 170, 225, 0.4);
}

.btn-outline-windows.disabled, .btn-outline-windows:disabled {
  border-color: #26aae1 !important;
  background: transparent !important;
  color: #26aae1 !important;
}

.btn-outline-windows:active,
.btn-outline-windows.active,
.show > .btn-outline-windows.dropdown-toggle {
  border-color: transparent;
  background: #24a2d6;
  color: #fff;
  box-shadow: none;
}

.btn-outline-windows .badge {
  background: #26aae1;
  border-color: #26aae1;
  color: #fff;
}

.btn-outline-windows:hover .badge,
.btn-outline-windows:focus .badge,
.btn-outline-windows:active .badge,
.btn-outline-windows.active .badge,
.show > .btn-outline-windows.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #26aae1;
}

.text-macos {
  color: #343434 !important;
}

a.text-macos:hover, a.text-macos:focus {
  color: #0e0e0e !important;
}

.bg-macos {
  background-color: #343434 !important;
}

a.bg-macos:hover, a.bg-macos:focus {
  background-color: #313131 !important;
}

.bg-macos-dark {
  background-color: #2f2f2f !important;
}

a.bg-macos-dark:hover, a.bg-macos-dark:focus {
  background-color: #2d2d2d !important;
}

.bg-macos-darker {
  background-color: #2c2c2c !important;
}

a.bg-macos-darker:hover, a.bg-macos-darker:focus {
  background-color: #2a2a2a !important;
}

.badge-outline-macos {
  background-color: transparent;
  box-shadow: 0 0 0 1px #343434 inset;
  color: #343434;
}

.badge-outline-macos[href]:hover, .badge-outline-macos[href]:focus {
  color: #343434;
  text-decoration: none;
}

.btn .badge-outline-macos {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #343434 inset !important;
  color: #343434 !important;
}

.btn-macos {
  border-color: transparent;
  background: #343434;
  color: #fff;
}

.btn-macos:hover {
  border-color: transparent;
  background: #313131;
  color: #fff;
}

.btn-macos:focus, .btn-macos.focus {
  box-shadow: 0 0 0 2px rgba(52, 52, 52, 0.4);
}

.btn-macos.disabled, .btn-macos:disabled {
  border-color: transparent !important;
  background: #343434 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-macos .badge {
  background: #fff;
  color: #343434;
}

.btn-macos:active,
.btn-macos.active,
.show > .btn-macos.dropdown-toggle {
  border-color: transparent;
  background: #2d2d2d;
  box-shadow: none;
}

.btn-group .btn-macos,
.input-group-prepend .btn-macos,
.input-group-append .btn-macos {
  border-right: 1px solid #2d2d2d;
  border-left: 1px solid #2d2d2d;
}

.btn-outline-macos {
  border-color: #343434;
  background: transparent;
  color: #343434;
}

.btn-outline-macos:hover {
  border-color: transparent;
  background: #343434;
  color: #fff;
}

.btn-outline-macos:focus, .btn-outline-macos.focus {
  box-shadow: 0 0 0 2px rgba(52, 52, 52, 0.4);
}

.btn-outline-macos.disabled, .btn-outline-macos:disabled {
  border-color: #343434 !important;
  background: transparent !important;
  color: #343434 !important;
}

.btn-outline-macos:active,
.btn-outline-macos.active,
.show > .btn-outline-macos.dropdown-toggle {
  border-color: transparent;
  background: #313131;
  color: #fff;
  box-shadow: none;
}

.btn-outline-macos .badge {
  background: #343434;
  border-color: #343434;
  color: #fff;
}

.btn-outline-macos:hover .badge,
.btn-outline-macos:focus .badge,
.btn-outline-macos:active .badge,
.btn-outline-macos.active .badge,
.show > .btn-outline-macos.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #343434;
}

.text-linux {
  color: #800080 !important;
}

a.text-linux:hover, a.text-linux:focus {
  color: #340034 !important;
}

.bg-linux {
  background-color: #800080 !important;
}

a.bg-linux:hover, a.bg-linux:focus {
  background-color: #7a007a !important;
}

.bg-linux-dark {
  background-color: #730073 !important;
}

a.bg-linux-dark:hover, a.bg-linux-dark:focus {
  background-color: #6d006d !important;
}

.bg-linux-darker {
  background-color: #6d006d !important;
}

a.bg-linux-darker:hover, a.bg-linux-darker:focus {
  background-color: #680068 !important;
}

.badge-outline-linux {
  background-color: transparent;
  box-shadow: 0 0 0 1px #800080 inset;
  color: #800080;
}

.badge-outline-linux[href]:hover, .badge-outline-linux[href]:focus {
  color: #800080;
  text-decoration: none;
}

.btn .badge-outline-linux {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #800080 inset !important;
  color: #800080 !important;
}

.btn-linux {
  border-color: transparent;
  background: #800080;
  color: #fff;
}

.btn-linux:hover {
  border-color: transparent;
  background: #7a007a;
  color: #fff;
}

.btn-linux:focus, .btn-linux.focus {
  box-shadow: 0 0 0 2px rgba(128, 0, 128, 0.4);
}

.btn-linux.disabled, .btn-linux:disabled {
  border-color: transparent !important;
  background: #800080 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-linux .badge {
  background: #fff;
  color: #800080;
}

.btn-linux:active,
.btn-linux.active,
.show > .btn-linux.dropdown-toggle {
  border-color: transparent;
  background: #6f006f;
  box-shadow: none;
}

.btn-group .btn-linux,
.input-group-prepend .btn-linux,
.input-group-append .btn-linux {
  border-right: 1px solid #6f006f;
  border-left: 1px solid #6f006f;
}

.btn-outline-linux {
  border-color: #800080;
  background: transparent;
  color: #800080;
}

.btn-outline-linux:hover {
  border-color: transparent;
  background: #800080;
  color: #fff;
}

.btn-outline-linux:focus, .btn-outline-linux.focus {
  box-shadow: 0 0 0 2px rgba(128, 0, 128, 0.4);
}

.btn-outline-linux.disabled, .btn-outline-linux:disabled {
  border-color: #800080 !important;
  background: transparent !important;
  color: #800080 !important;
}

.btn-outline-linux:active,
.btn-outline-linux.active,
.show > .btn-outline-linux.dropdown-toggle {
  border-color: transparent;
  background: #7a007a;
  color: #fff;
  box-shadow: none;
}

.btn-outline-linux .badge {
  background: #800080;
  border-color: #800080;
  color: #fff;
}

.btn-outline-linux:hover .badge,
.btn-outline-linux:focus .badge,
.btn-outline-linux:active .badge,
.btn-outline-linux.active .badge,
.show > .btn-outline-linux.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #800080;
}

.text-blue {
  color: #1e70cd !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #144c8a !important;
}

.bg-blue {
  background-color: #1e70cd !important;
}

a.bg-blue:hover, a.bg-blue:focus {
  background-color: #1d6ac3 !important;
}

.bg-blue-dark {
  background-color: #1b65b9 !important;
}

a.bg-blue-dark:hover, a.bg-blue-dark:focus {
  background-color: #1a60b0 !important;
}

.bg-blue-darker {
  background-color: #1a5fae !important;
}

a.bg-blue-darker:hover, a.bg-blue-darker:focus {
  background-color: #195aa5 !important;
}

.border-blue {
  border-color: #1e70cd !important;
}

.badge-outline-blue {
  background-color: transparent;
  box-shadow: 0 0 0 1px #1e70cd inset;
  color: #1e70cd;
}

.badge-outline-blue[href]:hover, .badge-outline-blue[href]:focus {
  color: #1e70cd;
  text-decoration: none;
}

.btn .badge-outline-blue {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #1e70cd inset !important;
  color: #1e70cd !important;
}

.btn-blue {
  border-color: transparent;
  background: #1e70cd;
  color: #fff;
}

.btn-blue:hover {
  border-color: transparent;
  background: #1d6ac3;
  color: #fff;
}

.btn-blue:focus, .btn-blue.focus {
  box-shadow: 0 0 0 2px rgba(30, 112, 205, 0.4);
}

.btn-blue.disabled, .btn-blue:disabled {
  border-color: transparent !important;
  background: #1e70cd !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-blue .badge {
  background: #fff;
  color: #1e70cd;
}

.btn-blue:active,
.btn-blue.active,
.show > .btn-blue.dropdown-toggle {
  border-color: transparent;
  background: #1a61b2;
  box-shadow: none;
}

.btn-group .btn-blue,
.input-group-prepend .btn-blue,
.input-group-append .btn-blue {
  border-right: 1px solid #1a61b2;
  border-left: 1px solid #1a61b2;
}

.btn-outline-blue {
  border-color: #1e70cd;
  background: transparent;
  color: #1e70cd;
}

.btn-outline-blue:hover {
  border-color: transparent;
  background: #1e70cd;
  color: #fff;
}

.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 2px rgba(30, 112, 205, 0.4);
}

.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  border-color: #1e70cd !important;
  background: transparent !important;
  color: #1e70cd !important;
}

.btn-outline-blue:active,
.btn-outline-blue.active,
.show > .btn-outline-blue.dropdown-toggle {
  border-color: transparent;
  background: #1d6ac3;
  color: #fff;
  box-shadow: none;
}

.btn-outline-blue .badge {
  background: #1e70cd;
  border-color: #1e70cd;
  color: #fff;
}

.btn-outline-blue:hover .badge,
.btn-outline-blue:focus .badge,
.btn-outline-blue:active .badge,
.btn-outline-blue.active .badge,
.show > .btn-outline-blue.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #1e70cd;
}

.text-indigo {
  color: #6610f2 !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #4709ac !important;
}

.bg-indigo {
  background-color: #6610f2 !important;
}

a.bg-indigo:hover, a.bg-indigo:focus {
  background-color: #610fe6 !important;
}

.bg-indigo-dark {
  background-color: #5c0eda !important;
}

a.bg-indigo-dark:hover, a.bg-indigo-dark:focus {
  background-color: #570dcf !important;
}

.bg-indigo-darker {
  background-color: #570ece !important;
}

a.bg-indigo-darker:hover, a.bg-indigo-darker:focus {
  background-color: #530dc4 !important;
}

.border-indigo {
  border-color: #6610f2 !important;
}

.badge-outline-indigo {
  background-color: transparent;
  box-shadow: 0 0 0 1px #6610f2 inset;
  color: #6610f2;
}

.badge-outline-indigo[href]:hover, .badge-outline-indigo[href]:focus {
  color: #6610f2;
  text-decoration: none;
}

.btn .badge-outline-indigo {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #6610f2 inset !important;
  color: #6610f2 !important;
}

.btn-indigo {
  border-color: transparent;
  background: #6610f2;
  color: #fff;
}

.btn-indigo:hover {
  border-color: transparent;
  background: #610fe6;
  color: #fff;
}

.btn-indigo:focus, .btn-indigo.focus {
  box-shadow: 0 0 0 2px rgba(102, 16, 242, 0.4);
}

.btn-indigo.disabled, .btn-indigo:disabled {
  border-color: transparent !important;
  background: #6610f2 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-indigo .badge {
  background: #fff;
  color: #6610f2;
}

.btn-indigo:active,
.btn-indigo.active,
.show > .btn-indigo.dropdown-toggle {
  border-color: transparent;
  background: #590ed3;
  box-shadow: none;
}

.btn-group .btn-indigo,
.input-group-prepend .btn-indigo,
.input-group-append .btn-indigo {
  border-right: 1px solid #590ed3;
  border-left: 1px solid #590ed3;
}

.btn-outline-indigo {
  border-color: #6610f2;
  background: transparent;
  color: #6610f2;
}

.btn-outline-indigo:hover {
  border-color: transparent;
  background: #6610f2;
  color: #fff;
}

.btn-outline-indigo:focus, .btn-outline-indigo.focus {
  box-shadow: 0 0 0 2px rgba(102, 16, 242, 0.4);
}

.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
  border-color: #6610f2 !important;
  background: transparent !important;
  color: #6610f2 !important;
}

.btn-outline-indigo:active,
.btn-outline-indigo.active,
.show > .btn-outline-indigo.dropdown-toggle {
  border-color: transparent;
  background: #610fe6;
  color: #fff;
  box-shadow: none;
}

.btn-outline-indigo .badge {
  background: #6610f2;
  border-color: #6610f2;
  color: #fff;
}

.btn-outline-indigo:hover .badge,
.btn-outline-indigo:focus .badge,
.btn-outline-indigo:active .badge,
.btn-outline-indigo.active .badge,
.show > .btn-outline-indigo.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #6610f2;
}

.text-purple {
  color: #6f42c1 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #4e2d89 !important;
}

.bg-purple {
  background-color: #6f42c1 !important;
}

a.bg-purple:hover, a.bg-purple:focus {
  background-color: #693fb7 !important;
}

.bg-purple-dark {
  background-color: #643bae !important;
}

a.bg-purple-dark:hover, a.bg-purple-dark:focus {
  background-color: #5f38a5 !important;
}

.bg-purple-darker {
  background-color: #5e38a4 !important;
}

a.bg-purple-darker:hover, a.bg-purple-darker:focus {
  background-color: #59359c !important;
}

.border-purple {
  border-color: #6f42c1 !important;
}

.badge-outline-purple {
  background-color: transparent;
  box-shadow: 0 0 0 1px #6f42c1 inset;
  color: #6f42c1;
}

.badge-outline-purple[href]:hover, .badge-outline-purple[href]:focus {
  color: #6f42c1;
  text-decoration: none;
}

.btn .badge-outline-purple {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #6f42c1 inset !important;
  color: #6f42c1 !important;
}

.btn-purple {
  border-color: transparent;
  background: #6f42c1;
  color: #fff;
}

.btn-purple:hover {
  border-color: transparent;
  background: #693fb7;
  color: #fff;
}

.btn-purple:focus, .btn-purple.focus {
  box-shadow: 0 0 0 2px rgba(111, 66, 193, 0.4);
}

.btn-purple.disabled, .btn-purple:disabled {
  border-color: transparent !important;
  background: #6f42c1 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-purple .badge {
  background: #fff;
  color: #6f42c1;
}

.btn-purple:active,
.btn-purple.active,
.show > .btn-purple.dropdown-toggle {
  border-color: transparent;
  background: #6139a8;
  box-shadow: none;
}

.btn-group .btn-purple,
.input-group-prepend .btn-purple,
.input-group-append .btn-purple {
  border-right: 1px solid #6139a8;
  border-left: 1px solid #6139a8;
}

.btn-outline-purple {
  border-color: #6f42c1;
  background: transparent;
  color: #6f42c1;
}

.btn-outline-purple:hover {
  border-color: transparent;
  background: #6f42c1;
  color: #fff;
}

.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 2px rgba(111, 66, 193, 0.4);
}

.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  border-color: #6f42c1 !important;
  background: transparent !important;
  color: #6f42c1 !important;
}

.btn-outline-purple:active,
.btn-outline-purple.active,
.show > .btn-outline-purple.dropdown-toggle {
  border-color: transparent;
  background: #693fb7;
  color: #fff;
  box-shadow: none;
}

.btn-outline-purple .badge {
  background: #6f42c1;
  border-color: #6f42c1;
  color: #fff;
}

.btn-outline-purple:hover .badge,
.btn-outline-purple:focus .badge,
.btn-outline-purple:active .badge,
.btn-outline-purple.active .badge,
.show > .btn-outline-purple.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #6f42c1;
}

.text-pink {
  color: #e83e8c !important;
}

a.text-pink:hover, a.text-pink:focus {
  color: #c21766 !important;
}

.bg-pink {
  background-color: #e83e8c !important;
}

a.bg-pink:hover, a.bg-pink:focus {
  background-color: #dc3b85 !important;
}

.bg-pink-dark {
  background-color: #d1387e !important;
}

a.bg-pink-dark:hover, a.bg-pink-dark:focus {
  background-color: #c73578 !important;
}

.bg-pink-darker {
  background-color: #c53577 !important;
}

a.bg-pink-darker:hover, a.bg-pink-darker:focus {
  background-color: #bb3271 !important;
}

.border-pink {
  border-color: #e83e8c !important;
}

.badge-outline-pink {
  background-color: transparent;
  box-shadow: 0 0 0 1px #e83e8c inset;
  color: #e83e8c;
}

.badge-outline-pink[href]:hover, .badge-outline-pink[href]:focus {
  color: #e83e8c;
  text-decoration: none;
}

.btn .badge-outline-pink {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #e83e8c inset !important;
  color: #e83e8c !important;
}

.btn-pink {
  border-color: transparent;
  background: #e83e8c;
  color: #fff;
}

.btn-pink:hover {
  border-color: transparent;
  background: #dc3b85;
  color: #fff;
}

.btn-pink:focus, .btn-pink.focus {
  box-shadow: 0 0 0 2px rgba(232, 62, 140, 0.4);
}

.btn-pink.disabled, .btn-pink:disabled {
  border-color: transparent !important;
  background: #e83e8c !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-pink .badge {
  background: #fff;
  color: #e83e8c;
}

.btn-pink:active,
.btn-pink.active,
.show > .btn-pink.dropdown-toggle {
  border-color: transparent;
  background: #ca367a;
  box-shadow: none;
}

.btn-group .btn-pink,
.input-group-prepend .btn-pink,
.input-group-append .btn-pink {
  border-right: 1px solid #ca367a;
  border-left: 1px solid #ca367a;
}

.btn-outline-pink {
  border-color: #e83e8c;
  background: transparent;
  color: #e83e8c;
}

.btn-outline-pink:hover {
  border-color: transparent;
  background: #e83e8c;
  color: #fff;
}

.btn-outline-pink:focus, .btn-outline-pink.focus {
  box-shadow: 0 0 0 2px rgba(232, 62, 140, 0.4);
}

.btn-outline-pink.disabled, .btn-outline-pink:disabled {
  border-color: #e83e8c !important;
  background: transparent !important;
  color: #e83e8c !important;
}

.btn-outline-pink:active,
.btn-outline-pink.active,
.show > .btn-outline-pink.dropdown-toggle {
  border-color: transparent;
  background: #dc3b85;
  color: #fff;
  box-shadow: none;
}

.btn-outline-pink .badge {
  background: #e83e8c;
  border-color: #e83e8c;
  color: #fff;
}

.btn-outline-pink:hover .badge,
.btn-outline-pink:focus .badge,
.btn-outline-pink:active .badge,
.btn-outline-pink.active .badge,
.show > .btn-outline-pink.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #e83e8c;
}

.text-orange {
  color: #feb744 !important;
}

a.text-orange:hover, a.text-orange:focus {
  color: #f49701 !important;
}

.bg-orange {
  background-color: #feb744 !important;
}

a.bg-orange:hover, a.bg-orange:focus {
  background-color: #f1ae41 !important;
}

.bg-orange-dark {
  background-color: #e5a53d !important;
}

a.bg-orange-dark:hover, a.bg-orange-dark:focus {
  background-color: #da9d3a !important;
}

.bg-orange-darker {
  background-color: #d89c3a !important;
}

a.bg-orange-darker:hover, a.bg-orange-darker:focus {
  background-color: #cd9437 !important;
}

.border-orange {
  border-color: #feb744 !important;
}

.badge-outline-orange {
  background-color: transparent;
  box-shadow: 0 0 0 1px #feb744 inset;
  color: #feb744;
}

.badge-outline-orange[href]:hover, .badge-outline-orange[href]:focus {
  color: #feb744;
  text-decoration: none;
}

.btn .badge-outline-orange {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #feb744 inset !important;
  color: #feb744 !important;
}

.btn-orange {
  border-color: transparent;
  background: #feb744;
  color: #66491b;
}

.btn-orange:hover {
  border-color: transparent;
  background: #f1ae41;
  color: #66491b;
}

.btn-orange:focus, .btn-orange.focus {
  box-shadow: 0 0 0 2px rgba(254, 183, 68, 0.4);
}

.btn-orange.disabled, .btn-orange:disabled {
  border-color: transparent !important;
  background: #feb744 !important;
  box-shadow: none !important;
  color: #66491b !important;
}

.btn-orange .badge {
  background: #66491b;
  color: #feb744;
}

.btn-orange:active,
.btn-orange.active,
.show > .btn-orange.dropdown-toggle {
  border-color: transparent;
  background: #dd9f3b;
  box-shadow: none;
}

.btn-group .btn-orange,
.input-group-prepend .btn-orange,
.input-group-append .btn-orange {
  border-right: 1px solid #dd9f3b;
  border-left: 1px solid #dd9f3b;
}

.btn-outline-orange {
  border-color: #feb744;
  background: transparent;
  color: #feb744;
}

.btn-outline-orange:hover {
  border-color: transparent;
  background: #feb744;
  color: #66491b;
}

.btn-outline-orange:focus, .btn-outline-orange.focus {
  box-shadow: 0 0 0 2px rgba(254, 183, 68, 0.4);
}

.btn-outline-orange.disabled, .btn-outline-orange:disabled {
  border-color: #feb744 !important;
  background: transparent !important;
  color: #feb744 !important;
}

.btn-outline-orange:active,
.btn-outline-orange.active,
.show > .btn-outline-orange.dropdown-toggle {
  border-color: transparent;
  background: #f1ae41;
  color: #66491b;
  box-shadow: none;
}

.btn-outline-orange .badge {
  background: #feb744;
  border-color: #feb744;
  color: #66491b;
}

.btn-outline-orange:hover .badge,
.btn-outline-orange:focus .badge,
.btn-outline-orange:active .badge,
.btn-outline-orange.active .badge,
.show > .btn-outline-orange.dropdown-toggle .badge {
  background: #66491b;
  border-color: #66491b;
  color: #feb744;
}

.text-teal {
  color: #20c997 !important;
}

a.text-teal:hover, a.text-teal:focus {
  color: #158765 !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

a.bg-teal:hover, a.bg-teal:focus {
  background-color: #1ebf8f !important;
}

.bg-teal-dark {
  background-color: #1db588 !important;
}

a.bg-teal-dark:hover, a.bg-teal-dark:focus {
  background-color: #1cac81 !important;
}

.bg-teal-darker {
  background-color: #1bab80 !important;
}

a.bg-teal-darker:hover, a.bg-teal-darker:focus {
  background-color: #1aa27a !important;
}

.border-teal {
  border-color: #20c997 !important;
}

.badge-outline-teal {
  background-color: transparent;
  box-shadow: 0 0 0 1px #20c997 inset;
  color: #20c997;
}

.badge-outline-teal[href]:hover, .badge-outline-teal[href]:focus {
  color: #20c997;
  text-decoration: none;
}

.btn .badge-outline-teal {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #20c997 inset !important;
  color: #20c997 !important;
}

.btn-teal {
  border-color: transparent;
  background: #20c997;
  color: #fff;
}

.btn-teal:hover {
  border-color: transparent;
  background: #1ebf8f;
  color: #fff;
}

.btn-teal:focus, .btn-teal.focus {
  box-shadow: 0 0 0 2px rgba(32, 201, 151, 0.4);
}

.btn-teal.disabled, .btn-teal:disabled {
  border-color: transparent !important;
  background: #20c997 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-teal .badge {
  background: #fff;
  color: #20c997;
}

.btn-teal:active,
.btn-teal.active,
.show > .btn-teal.dropdown-toggle {
  border-color: transparent;
  background: #1caf83;
  box-shadow: none;
}

.btn-group .btn-teal,
.input-group-prepend .btn-teal,
.input-group-append .btn-teal {
  border-right: 1px solid #1caf83;
  border-left: 1px solid #1caf83;
}

.btn-outline-teal {
  border-color: #20c997;
  background: transparent;
  color: #20c997;
}

.btn-outline-teal:hover {
  border-color: transparent;
  background: #20c997;
  color: #fff;
}

.btn-outline-teal:focus, .btn-outline-teal.focus {
  box-shadow: 0 0 0 2px rgba(32, 201, 151, 0.4);
}

.btn-outline-teal.disabled, .btn-outline-teal:disabled {
  border-color: #20c997 !important;
  background: transparent !important;
  color: #20c997 !important;
}

.btn-outline-teal:active,
.btn-outline-teal.active,
.show > .btn-outline-teal.dropdown-toggle {
  border-color: transparent;
  background: #1ebf8f;
  color: #fff;
  box-shadow: none;
}

.btn-outline-teal .badge {
  background: #20c997;
  border-color: #20c997;
  color: #fff;
}

.btn-outline-teal:hover .badge,
.btn-outline-teal:focus .badge,
.btn-outline-teal:active .badge,
.btn-outline-teal.active .badge,
.show > .btn-outline-teal.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #20c997;
}

.text-cyan {
  color: #28c3d7 !important;
}

a.text-cyan:hover, a.text-cyan:focus {
  color: #1c8997 !important;
}

.bg-cyan {
  background-color: #28c3d7 !important;
}

a.bg-cyan:hover, a.bg-cyan:focus {
  background-color: #26b9cc !important;
}

.bg-cyan-dark {
  background-color: #24b0c2 !important;
}

a.bg-cyan-dark:hover, a.bg-cyan-dark:focus {
  background-color: #22a7b8 !important;
}

.bg-cyan-darker {
  background-color: #22a6b7 !important;
}

a.bg-cyan-darker:hover, a.bg-cyan-darker:focus {
  background-color: #209eae !important;
}

.border-cyan {
  border-color: #28c3d7 !important;
}

.badge-outline-cyan {
  background-color: transparent;
  box-shadow: 0 0 0 1px #28c3d7 inset;
  color: #28c3d7;
}

.badge-outline-cyan[href]:hover, .badge-outline-cyan[href]:focus {
  color: #28c3d7;
  text-decoration: none;
}

.btn .badge-outline-cyan {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #28c3d7 inset !important;
  color: #28c3d7 !important;
}

.btn-cyan {
  border-color: transparent;
  background: #28c3d7;
  color: #fff;
}

.btn-cyan:hover {
  border-color: transparent;
  background: #26b9cc;
  color: #fff;
}

.btn-cyan:focus, .btn-cyan.focus {
  box-shadow: 0 0 0 2px rgba(40, 195, 215, 0.4);
}

.btn-cyan.disabled, .btn-cyan:disabled {
  border-color: transparent !important;
  background: #28c3d7 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-cyan .badge {
  background: #fff;
  color: #28c3d7;
}

.btn-cyan:active,
.btn-cyan.active,
.show > .btn-cyan.dropdown-toggle {
  border-color: transparent;
  background: #23aabb;
  box-shadow: none;
}

.btn-group .btn-cyan,
.input-group-prepend .btn-cyan,
.input-group-append .btn-cyan {
  border-right: 1px solid #23aabb;
  border-left: 1px solid #23aabb;
}

.btn-outline-cyan {
  border-color: #28c3d7;
  background: transparent;
  color: #28c3d7;
}

.btn-outline-cyan:hover {
  border-color: transparent;
  background: #28c3d7;
  color: #fff;
}

.btn-outline-cyan:focus, .btn-outline-cyan.focus {
  box-shadow: 0 0 0 2px rgba(40, 195, 215, 0.4);
}

.btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
  border-color: #28c3d7 !important;
  background: transparent !important;
  color: #28c3d7 !important;
}

.btn-outline-cyan:active,
.btn-outline-cyan.active,
.show > .btn-outline-cyan.dropdown-toggle {
  border-color: transparent;
  background: #26b9cc;
  color: #fff;
  box-shadow: none;
}

.btn-outline-cyan .badge {
  background: #28c3d7;
  border-color: #28c3d7;
  color: #fff;
}

.btn-outline-cyan:hover .badge,
.btn-outline-cyan:focus .badge,
.btn-outline-cyan:active .badge,
.btn-outline-cyan.active .badge,
.show > .btn-outline-cyan.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #28c3d7;
}

.text-green {
  color: #02bc77 !important;
}

a.text-green:hover, a.text-green:focus {
  color: #017047 !important;
}

.bg-green {
  background-color: #02bc77 !important;
}

a.bg-green:hover, a.bg-green:focus {
  background-color: #02b371 !important;
}

.bg-green-dark {
  background-color: #02a96b !important;
}

a.bg-green-dark:hover, a.bg-green-dark:focus {
  background-color: #02a166 !important;
}

.bg-green-darker {
  background-color: #02a065 !important;
}

a.bg-green-darker:hover, a.bg-green-darker:focus {
  background-color: #029860 !important;
}

.border-green {
  border-color: #02bc77 !important;
}

.badge-outline-green {
  background-color: transparent;
  box-shadow: 0 0 0 1px #02bc77 inset;
  color: #02bc77;
}

.badge-outline-green[href]:hover, .badge-outline-green[href]:focus {
  color: #02bc77;
  text-decoration: none;
}

.btn .badge-outline-green {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #02bc77 inset !important;
  color: #02bc77 !important;
}

.btn-green {
  border-color: transparent;
  background: #02bc77;
  color: #fff;
}

.btn-green:hover {
  border-color: transparent;
  background: #02b371;
  color: #fff;
}

.btn-green:focus, .btn-green.focus {
  box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4);
}

.btn-green.disabled, .btn-green:disabled {
  border-color: transparent !important;
  background: #02bc77 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-green .badge {
  background: #fff;
  color: #02bc77;
}

.btn-green:active,
.btn-green.active,
.show > .btn-green.dropdown-toggle {
  border-color: transparent;
  background: #02a468;
  box-shadow: none;
}

.btn-group .btn-green,
.input-group-prepend .btn-green,
.input-group-append .btn-green {
  border-right: 1px solid #02a468;
  border-left: 1px solid #02a468;
}

.btn-outline-green {
  border-color: #02bc77;
  background: transparent;
  color: #02bc77;
}

.btn-outline-green:hover {
  border-color: transparent;
  background: #02bc77;
  color: #fff;
}

.btn-outline-green:focus, .btn-outline-green.focus {
  box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4);
}

.btn-outline-green.disabled, .btn-outline-green:disabled {
  border-color: #02bc77 !important;
  background: transparent !important;
  color: #02bc77 !important;
}

.btn-outline-green:active,
.btn-outline-green.active,
.show > .btn-outline-green.dropdown-toggle {
  border-color: transparent;
  background: #02b371;
  color: #fff;
  box-shadow: none;
}

.btn-outline-green .badge {
  background: #02bc77;
  border-color: #02bc77;
  color: #fff;
}

.btn-outline-green:hover .badge,
.btn-outline-green:focus .badge,
.btn-outline-green:active .badge,
.btn-outline-green.active .badge,
.show > .btn-outline-green.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #02bc77;
}

.btn-white {
  border-color: transparent;
  background: #fff;
  color: #4e5155;
}

.btn-white:hover {
  border-color: transparent;
  background: #f2f2f2;
  color: #4e5155;
}

.btn-white:focus, .btn-white.focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
}

.btn-white.disabled, .btn-white:disabled {
  border-color: transparent !important;
  background: #fff !important;
  box-shadow: none !important;
  color: #4e5155 !important;
}

.btn-white .badge {
  background: #4e5155;
  color: #fff;
}

.btn-white:active,
.btn-white.active,
.show > .btn-white.dropdown-toggle {
  border-color: transparent;
  background: #dedede;
  box-shadow: none;
}

.btn-group .btn-white,
.input-group-prepend .btn-white,
.input-group-append .btn-white {
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
}

.btn-outline-white {
  border-color: #fff;
  background: transparent;
  color: #fff;
}

.btn-outline-white:hover {
  border-color: transparent;
  background: #fff;
  color: #4e5155;
}

.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  border-color: #fff !important;
  background: transparent !important;
  color: #fff !important;
}

.btn-outline-white:active,
.btn-outline-white.active,
.show > .btn-outline-white.dropdown-toggle {
  border-color: transparent;
  background: #f2f2f2;
  color: #4e5155;
  box-shadow: none;
}

.btn-outline-white .badge {
  background: #fff;
  border-color: #fff;
  color: #666666;
}

.btn-outline-white:hover .badge,
.btn-outline-white:focus .badge,
.btn-outline-white:active .badge,
.btn-outline-white.active .badge,
.show > .btn-outline-white.dropdown-toggle .badge {
  background: #4e5155;
  border-color: #4e5155;
  color: #fff;
}

.custom-control.custom-control-black .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-black .custom-control-input:active ~ .custom-control-label::before {
  background-color: #000;
}

.custom-control.custom-control-black .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-black .custom-control-input:active ~ .custom-control-label::before {
  border-color: #000;
}

.custom-control.custom-control-black .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
}

.custom-control.custom-control-black.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-black.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-black.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #000;
  background-color: #000;
}

.custom-control.custom-control-black.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-black.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-black.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-control.custom-control-white .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-white .custom-control-input:active ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-control.custom-control-white .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-white .custom-control-input:active ~ .custom-control-label::before {
  border-color: #fff;
}

.custom-control.custom-control-white .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
}

.custom-control.custom-control-white.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-white.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-white.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #fff;
  background-color: #fff;
}

.custom-control.custom-control-white.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23666666' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-white.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23666666;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-white.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23666666'/%3E%3C/svg%3E");
}

.custom-control.custom-control-silver .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-silver .custom-control-input:active ~ .custom-control-label::before {
  background-color: #eee;
}

.custom-control.custom-control-silver .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-silver .custom-control-input:active ~ .custom-control-label::before {
  border-color: #eee;
}

.custom-control.custom-control-silver .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(238, 238, 238, 0.4);
}

.custom-control.custom-control-silver.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-silver.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-silver.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #eee;
  background-color: #eee;
}

.custom-control.custom-control-silver.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%235f5f5f' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-silver.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%235f5f5f;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-silver.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%235f5f5f'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gray .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-gray .custom-control-input:active ~ .custom-control-label::before {
  background-color: #777;
}

.custom-control.custom-control-gray .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-gray .custom-control-input:active ~ .custom-control-label::before {
  border-color: #777;
}

.custom-control.custom-control-gray .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(119, 119, 119, 0.4);
}

.custom-control.custom-control-gray.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-gray.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-gray.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #777;
  background-color: #777;
}

.custom-control.custom-control-gray.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gray.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gray.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gold .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-gold .custom-control-input:active ~ .custom-control-label::before {
  background-color: #ffeb3b;
}

.custom-control.custom-control-gold .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-gold .custom-control-input:active ~ .custom-control-label::before {
  border-color: #ffeb3b;
}

.custom-control.custom-control-gold .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(255, 235, 59, 0.4);
}

.custom-control.custom-control-gold.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-gold.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-gold.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ffeb3b;
  background-color: #ffeb3b;
}

.custom-control.custom-control-gold.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23665e18' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gold.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23665e18;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gold.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23665e18'/%3E%3C/svg%3E");
}

.custom-control.custom-control-pink .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-pink .custom-control-input:active ~ .custom-control-label::before {
  background-color: #e91e63;
}

.custom-control.custom-control-pink .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-pink .custom-control-input:active ~ .custom-control-label::before {
  border-color: #e91e63;
}

.custom-control.custom-control-pink .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(233, 30, 99, 0.4);
}

.custom-control.custom-control-pink.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-pink.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-pink.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #e91e63;
  background-color: #e91e63;
}

.custom-control.custom-control-pink.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-pink.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-pink.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-control.custom-control-red .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-red .custom-control-input:active ~ .custom-control-label::before {
  background-color: #f44336;
}

.custom-control.custom-control-red .custom-control-input:focus ~ .custom-control-label::before,
.custom-control.custom-control-red .custom-control-input:active ~ .custom-control-label::before {
  border-color: #f44336;
}

.custom-control.custom-control-red .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(244, 67, 54, 0.4);
}

.custom-control.custom-control-red.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-red.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-red.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #f44336;
  background-color: #f44336;
}

.custom-control.custom-control-red.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-red.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-red.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.navbar.bg-secondary {
  background-color: #8897aa !important;
  color: #ebeef1;
}

.navbar.bg-secondary .navbar-brand {
  color: #fff;
}

.navbar.bg-secondary .navbar-brand:hover, .navbar.bg-secondary .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-secondary .navbar-nav .nav-link {
  color: #ebeef1;
}

.navbar.bg-secondary .navbar-nav .nav-link:hover, .navbar.bg-secondary .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-secondary .navbar-nav .nav-link.disabled {
  color: #c3cbd5 !important;
}

.navbar.bg-secondary .navbar-nav .show > .nav-link,
.navbar.bg-secondary .navbar-nav .active > .nav-link,
.navbar.bg-secondary .navbar-nav .nav-link.show,
.navbar.bg-secondary .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-secondary .navbar-toggler {
  color: #ebeef1;
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-secondary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-secondary .navbar-text {
  color: #ebeef1;
}

.navbar.bg-secondary .navbar-text a {
  color: #fff;
}

.navbar.bg-secondary .navbar-text a:hover, .navbar.bg-secondary .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-secondary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-success {
  background-color: #02bc77 !important;
  color: #cbf1e3;
}

.navbar.bg-success .navbar-brand {
  color: #fff;
}

.navbar.bg-success .navbar-brand:hover, .navbar.bg-success .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-success .navbar-nav .nav-link {
  color: #cbf1e3;
}

.navbar.bg-success .navbar-nav .nav-link:hover, .navbar.bg-success .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-success .navbar-nav .nav-link.disabled {
  color: #7bdcb8 !important;
}

.navbar.bg-success .navbar-nav .show > .nav-link,
.navbar.bg-success .navbar-nav .active > .nav-link,
.navbar.bg-success .navbar-nav .nav-link.show,
.navbar.bg-success .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-success .navbar-toggler {
  color: #cbf1e3;
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-success .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-success .navbar-text {
  color: #cbf1e3;
}

.navbar.bg-success .navbar-text a {
  color: #fff;
}

.navbar.bg-success .navbar-text a:hover, .navbar.bg-success .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-success hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-info {
  background-color: #28c3d7 !important;
  color: #dcf5f8;
}

.navbar.bg-info .navbar-brand {
  color: #fff;
}

.navbar.bg-info .navbar-brand:hover, .navbar.bg-info .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-info .navbar-nav .nav-link {
  color: #dcf5f8;
}

.navbar.bg-info .navbar-nav .nav-link:hover, .navbar.bg-info .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-info .navbar-nav .nav-link.disabled {
  color: #94e1eb !important;
}

.navbar.bg-info .navbar-nav .show > .nav-link,
.navbar.bg-info .navbar-nav .active > .nav-link,
.navbar.bg-info .navbar-nav .nav-link.show,
.navbar.bg-info .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-info .navbar-toggler {
  color: #dcf5f8;
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-info .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-info .navbar-text {
  color: #dcf5f8;
}

.navbar.bg-info .navbar-text a {
  color: #fff;
}

.navbar.bg-info .navbar-text a:hover, .navbar.bg-info .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-info hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-warning {
  background-color: #ffd950 !important;
  color: #998230;
}

.navbar.bg-warning .navbar-brand {
  color: #665720;
}

.navbar.bg-warning .navbar-brand:hover, .navbar.bg-warning .navbar-brand:focus {
  color: #665720;
}

.navbar.bg-warning .navbar-nav .nav-link {
  color: #998230;
}

.navbar.bg-warning .navbar-nav .nav-link:hover, .navbar.bg-warning .navbar-nav .nav-link:focus {
  color: #665720;
}

.navbar.bg-warning .navbar-nav .nav-link.disabled {
  color: #c2a53d !important;
}

.navbar.bg-warning .navbar-nav .show > .nav-link,
.navbar.bg-warning .navbar-nav .active > .nav-link,
.navbar.bg-warning .navbar-nav .nav-link.show,
.navbar.bg-warning .navbar-nav .nav-link.active {
  color: #665720;
}

.navbar.bg-warning .navbar-toggler {
  color: #998230;
  border-color: rgba(102, 87, 32, 0.0832855);
}

.navbar.bg-warning .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(24, 28, 33, 0.4)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-warning .navbar-text {
  color: #998230;
}

.navbar.bg-warning .navbar-text a {
  color: #665720;
}

.navbar.bg-warning .navbar-text a:hover, .navbar.bg-warning .navbar-text a:focus {
  color: #665720;
}

.navbar.bg-warning hr {
  border-color: rgba(102, 87, 32, 0.0832855);
}

.navbar.bg-danger {
  background-color: #d9534f !important;
  color: #f7dbda;
}

.navbar.bg-danger .navbar-brand {
  color: #fff;
}

.navbar.bg-danger .navbar-brand:hover, .navbar.bg-danger .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-danger .navbar-nav .nav-link {
  color: #f7dbda;
}

.navbar.bg-danger .navbar-nav .nav-link:hover, .navbar.bg-danger .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-danger .navbar-nav .nav-link.disabled {
  color: #eba5a2 !important;
}

.navbar.bg-danger .navbar-nav .show > .nav-link,
.navbar.bg-danger .navbar-nav .active > .nav-link,
.navbar.bg-danger .navbar-nav .nav-link.show,
.navbar.bg-danger .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-danger .navbar-toggler {
  color: #f7dbda;
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-danger .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-danger .navbar-text {
  color: #f7dbda;
}

.navbar.bg-danger .navbar-text a {
  color: #fff;
}

.navbar.bg-danger .navbar-text a:hover, .navbar.bg-danger .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-danger hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-dark {
  background-color: #2f3337 !important;
  color: #9d9fa1;
}

.navbar.bg-dark .navbar-brand {
  color: #fff;
}

.navbar.bg-dark .navbar-brand:hover, .navbar.bg-dark .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-dark .navbar-nav .nav-link {
  color: #9d9fa1;
}

.navbar.bg-dark .navbar-nav .nav-link:hover, .navbar.bg-dark .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-dark .navbar-nav .nav-link.disabled {
  color: #717477 !important;
}

.navbar.bg-dark .navbar-nav .show > .nav-link,
.navbar.bg-dark .navbar-nav .active > .nav-link,
.navbar.bg-dark .navbar-nav .nav-link.show,
.navbar.bg-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-dark .navbar-toggler {
  color: #9d9fa1;
  border-color: rgba(255, 255, 255, 0.06);
}

.navbar.bg-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-dark .navbar-text {
  color: #9d9fa1;
}

.navbar.bg-dark .navbar-text a {
  color: #fff;
}

.navbar.bg-dark .navbar-text a:hover, .navbar.bg-dark .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-dark hr {
  border-color: rgba(255, 255, 255, 0.06);
}

.navbar.bg-white {
  background-color: #fff !important;
  color: #a3a4a6;
}

.navbar.bg-white .navbar-brand {
  color: #4e5155;
}

.navbar.bg-white .navbar-brand:hover, .navbar.bg-white .navbar-brand:focus {
  color: #4e5155;
}

.navbar.bg-white .navbar-nav .nav-link {
  color: #a3a4a6;
}

.navbar.bg-white .navbar-nav .nav-link:hover, .navbar.bg-white .navbar-nav .nav-link:focus {
  color: #4e5155;
}

.navbar.bg-white .navbar-nav .nav-link.disabled {
  color: #c8c8ca !important;
}

.navbar.bg-white .navbar-nav .show > .nav-link,
.navbar.bg-white .navbar-nav .active > .nav-link,
.navbar.bg-white .navbar-nav .nav-link.show,
.navbar.bg-white .navbar-nav .nav-link.active {
  color: #4e5155;
}

.navbar.bg-white .navbar-toggler {
  color: #a3a4a6;
  border-color: rgba(78, 81, 85, 0.075);
}

.navbar.bg-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(24, 28, 33, 0.4)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-white .navbar-text {
  color: #a3a4a6;
}

.navbar.bg-white .navbar-text a {
  color: #4e5155;
}

.navbar.bg-white .navbar-text a:hover, .navbar.bg-white .navbar-text a:focus {
  color: #4e5155;
}

.navbar.bg-white hr {
  border-color: rgba(78, 81, 85, 0.075);
}

.navbar.bg-light {
  background-color: #f1f1f2 !important;
  color: #a3a4a6;
}

.navbar.bg-light .navbar-brand {
  color: #4e5155;
}

.navbar.bg-light .navbar-brand:hover, .navbar.bg-light .navbar-brand:focus {
  color: #4e5155;
}

.navbar.bg-light .navbar-nav .nav-link {
  color: #a3a4a6;
}

.navbar.bg-light .navbar-nav .nav-link:hover, .navbar.bg-light .navbar-nav .nav-link:focus {
  color: #4e5155;
}

.navbar.bg-light .navbar-nav .nav-link.disabled {
  color: #c2c3c4 !important;
}

.navbar.bg-light .navbar-nav .show > .nav-link,
.navbar.bg-light .navbar-nav .active > .nav-link,
.navbar.bg-light .navbar-nav .nav-link.show,
.navbar.bg-light .navbar-nav .nav-link.active {
  color: #4e5155;
}

.navbar.bg-light .navbar-toggler {
  color: #a3a4a6;
  border-color: rgba(78, 81, 85, 0.0777227);
}

.navbar.bg-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(24, 28, 33, 0.4)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-light .navbar-text {
  color: #a3a4a6;
}

.navbar.bg-light .navbar-text a {
  color: #4e5155;
}

.navbar.bg-light .navbar-text a:hover, .navbar.bg-light .navbar-text a:focus {
  color: #4e5155;
}

.navbar.bg-light hr {
  border-color: rgba(78, 81, 85, 0.0777227);
}

.navbar.bg-lighter {
  background-color: #f8f8f8 !important;
  color: #a3a4a6;
}

.navbar.bg-lighter .navbar-brand {
  color: #4e5155;
}

.navbar.bg-lighter .navbar-brand:hover, .navbar.bg-lighter .navbar-brand:focus {
  color: #4e5155;
}

.navbar.bg-lighter .navbar-nav .nav-link {
  color: #a3a4a6;
}

.navbar.bg-lighter .navbar-nav .nav-link:hover, .navbar.bg-lighter .navbar-nav .nav-link:focus {
  color: #4e5155;
}

.navbar.bg-lighter .navbar-nav .nav-link.disabled {
  color: #c5c6c7 !important;
}

.navbar.bg-lighter .navbar-nav .show > .nav-link,
.navbar.bg-lighter .navbar-nav .active > .nav-link,
.navbar.bg-lighter .navbar-nav .nav-link.show,
.navbar.bg-lighter .navbar-nav .nav-link.active {
  color: #4e5155;
}

.navbar.bg-lighter .navbar-toggler {
  color: #a3a4a6;
  border-color: rgba(78, 81, 85, 0.0763725);
}

.navbar.bg-lighter .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(24, 28, 33, 0.4)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-lighter .navbar-text {
  color: #a3a4a6;
}

.navbar.bg-lighter .navbar-text a {
  color: #4e5155;
}

.navbar.bg-lighter .navbar-text a:hover, .navbar.bg-lighter .navbar-text a:focus {
  color: #4e5155;
}

.navbar.bg-lighter hr {
  border-color: rgba(78, 81, 85, 0.0763725);
}

.sidenav.bg-secondary {
  background-color: #8897aa !important;
  color: #ebeef1;
}

.sidenav.bg-secondary .sidenav-link,
.sidenav.bg-secondary .sidenav-horizontal-prev,
.sidenav.bg-secondary .sidenav-horizontal-next {
  color: #ebeef1;
}

.sidenav.bg-secondary .sidenav-link:hover, .sidenav.bg-secondary .sidenav-link:focus,
.sidenav.bg-secondary .sidenav-horizontal-prev:hover,
.sidenav.bg-secondary .sidenav-horizontal-prev:focus,
.sidenav.bg-secondary .sidenav-horizontal-next:hover,
.sidenav.bg-secondary .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-secondary .sidenav-link.active,
.sidenav.bg-secondary .sidenav-horizontal-prev.active,
.sidenav.bg-secondary .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-secondary .sidenav-item.disabled .sidenav-link,
.sidenav.bg-secondary .sidenav-horizontal-prev.disabled,
.sidenav.bg-secondary .sidenav-horizontal-next.disabled {
  color: #c3cbd5 !important;
}

.sidenav.bg-secondary .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-secondary .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-secondary .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #818fa2;
}

.sidenav.bg-secondary.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #7b889a;
}

.sidenav.bg-secondary.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-secondary.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #818fa2;
}

.sidenav.bg-secondary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-secondary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #ebeef1;
}

.sidenav.bg-secondary .sidenav-text {
  color: #fff;
}

.sidenav.bg-secondary .sidenav-header {
  color: #d2d8df;
}

.sidenav.bg-secondary hr,
.sidenav.bg-secondary .sidenav-divider,
.sidenav.bg-secondary .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.sidenav.bg-secondary .sidenav-inner > .sidenav-header::before,
.sidenav.bg-secondary .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #94a0b0;
}

.sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #818fa2;
}

.sidenav.bg-secondary .ps__thumb-y,
.sidenav.bg-secondary .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.633225) !important;
}

.sidenav.bg-success {
  background-color: #02bc77 !important;
  color: #cbf1e3;
}

.sidenav.bg-success .sidenav-link,
.sidenav.bg-success .sidenav-horizontal-prev,
.sidenav.bg-success .sidenav-horizontal-next {
  color: #cbf1e3;
}

.sidenav.bg-success .sidenav-link:hover, .sidenav.bg-success .sidenav-link:focus,
.sidenav.bg-success .sidenav-horizontal-prev:hover,
.sidenav.bg-success .sidenav-horizontal-prev:focus,
.sidenav.bg-success .sidenav-horizontal-next:hover,
.sidenav.bg-success .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-success .sidenav-link.active,
.sidenav.bg-success .sidenav-horizontal-prev.active,
.sidenav.bg-success .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-success .sidenav-item.disabled .sidenav-link,
.sidenav.bg-success .sidenav-horizontal-prev.disabled,
.sidenav.bg-success .sidenav-horizontal-next.disabled {
  color: #7bdcb8 !important;
}

.sidenav.bg-success .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-success .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-success .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #02b371;
}

.sidenav.bg-success.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #02aa6b;
}

.sidenav.bg-success.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-success.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #02b371;
}

.sidenav.bg-success .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-success .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #cbf1e3;
}

.sidenav.bg-success .sidenav-text {
  color: #fff;
}

.sidenav.bg-success .sidenav-header {
  color: #99e4c8;
}

.sidenav.bg-success hr,
.sidenav.bg-success .sidenav-divider,
.sidenav.bg-success .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.sidenav.bg-success .sidenav-inner > .sidenav-header::before,
.sidenav.bg-success .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #28be86;
}

.sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #02b371;
}

.sidenav.bg-success .ps__thumb-y,
.sidenav.bg-success .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.595325) !important;
}

.sidenav.bg-info {
  background-color: #28c3d7 !important;
  color: #dcf5f8;
}

.sidenav.bg-info .sidenav-link,
.sidenav.bg-info .sidenav-horizontal-prev,
.sidenav.bg-info .sidenav-horizontal-next {
  color: #dcf5f8;
}

.sidenav.bg-info .sidenav-link:hover, .sidenav.bg-info .sidenav-link:focus,
.sidenav.bg-info .sidenav-horizontal-prev:hover,
.sidenav.bg-info .sidenav-horizontal-prev:focus,
.sidenav.bg-info .sidenav-horizontal-next:hover,
.sidenav.bg-info .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-info .sidenav-link.active,
.sidenav.bg-info .sidenav-horizontal-prev.active,
.sidenav.bg-info .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-info .sidenav-item.disabled .sidenav-link,
.sidenav.bg-info .sidenav-horizontal-prev.disabled,
.sidenav.bg-info .sidenav-horizontal-next.disabled {
  color: #94e1eb !important;
}

.sidenav.bg-info .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-info .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-info .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #26b9cc;
}

.sidenav.bg-info.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #24b0c2;
}

.sidenav.bg-info.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-info.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #26b9cc;
}

.sidenav.bg-info .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-info .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #dcf5f8;
}

.sidenav.bg-info .sidenav-text {
  color: #fff;
}

.sidenav.bg-info .sidenav-header {
  color: #afe9f0;
}

.sidenav.bg-info hr,
.sidenav.bg-info .sidenav-divider,
.sidenav.bg-info .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.sidenav.bg-info .sidenav-inner > .sidenav-header::before,
.sidenav.bg-info .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #47c4d4;
}

.sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #26b9cc;
}

.sidenav.bg-info .ps__thumb-y,
.sidenav.bg-info .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.636761) !important;
}

.sidenav.bg-warning {
  background-color: #ffd950 !important;
  color: #998230;
}

.sidenav.bg-warning .sidenav-link,
.sidenav.bg-warning .sidenav-horizontal-prev,
.sidenav.bg-warning .sidenav-horizontal-next {
  color: #998230;
}

.sidenav.bg-warning .sidenav-link:hover, .sidenav.bg-warning .sidenav-link:focus,
.sidenav.bg-warning .sidenav-horizontal-prev:hover,
.sidenav.bg-warning .sidenav-horizontal-prev:focus,
.sidenav.bg-warning .sidenav-horizontal-next:hover,
.sidenav.bg-warning .sidenav-horizontal-next:focus {
  color: #665720;
}

.sidenav.bg-warning .sidenav-link.active,
.sidenav.bg-warning .sidenav-horizontal-prev.active,
.sidenav.bg-warning .sidenav-horizontal-next.active {
  color: #665720;
}

.sidenav.bg-warning .sidenav-item.disabled .sidenav-link,
.sidenav.bg-warning .sidenav-horizontal-prev.disabled,
.sidenav.bg-warning .sidenav-horizontal-next.disabled {
  color: #c2a53d !important;
}

.sidenav.bg-warning .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-warning .sidenav-item.active > .sidenav-link {
  color: #665720;
}

.sidenav.bg-warning .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #f9d44e;
}

.sidenav.bg-warning.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #f3cf4c;
}

.sidenav.bg-warning.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-warning.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #f9d44e;
}

.sidenav.bg-warning .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-warning .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #998230;
}

.sidenav.bg-warning .sidenav-text {
  color: #665720;
}

.sidenav.bg-warning .sidenav-header {
  color: #b39838;
}

.sidenav.bg-warning hr,
.sidenav.bg-warning .sidenav-divider,
.sidenav.bg-warning .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(102, 87, 32, 0.0832855) !important;
}

.sidenav.bg-warning .sidenav-inner > .sidenav-header::before,
.sidenav.bg-warning .sidenav-block::before {
  background-color: rgba(102, 87, 32, 0.0832855);
}

.sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #edca4a;
}

.sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #665720;
}

.sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #f9d44e;
}

.sidenav.bg-warning .ps__thumb-y,
.sidenav.bg-warning .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(102, 87, 32, 0.266284) !important;
}

.sidenav.bg-danger {
  background-color: #d9534f !important;
  color: #f7dbda;
}

.sidenav.bg-danger .sidenav-link,
.sidenav.bg-danger .sidenav-horizontal-prev,
.sidenav.bg-danger .sidenav-horizontal-next {
  color: #f7dbda;
}

.sidenav.bg-danger .sidenav-link:hover, .sidenav.bg-danger .sidenav-link:focus,
.sidenav.bg-danger .sidenav-horizontal-prev:hover,
.sidenav.bg-danger .sidenav-horizontal-prev:focus,
.sidenav.bg-danger .sidenav-horizontal-next:hover,
.sidenav.bg-danger .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-danger .sidenav-link.active,
.sidenav.bg-danger .sidenav-horizontal-prev.active,
.sidenav.bg-danger .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-danger .sidenav-item.disabled .sidenav-link,
.sidenav.bg-danger .sidenav-horizontal-prev.disabled,
.sidenav.bg-danger .sidenav-horizontal-next.disabled {
  color: #eba5a2 !important;
}

.sidenav.bg-danger .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-danger .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-danger .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #ce4f4b;
}

.sidenav.bg-danger.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #c44b47;
}

.sidenav.bg-danger.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-danger.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #ce4f4b;
}

.sidenav.bg-danger .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-danger .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #f7dbda;
}

.sidenav.bg-danger .sidenav-text {
  color: #fff;
}

.sidenav.bg-danger .sidenav-header {
  color: #f0b9b7;
}

.sidenav.bg-danger hr,
.sidenav.bg-danger .sidenav-divider,
.sidenav.bg-danger .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.sidenav.bg-danger .sidenav-inner > .sidenav-header::before,
.sidenav.bg-danger .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #d56966;
}

.sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #ce4f4b;
}

.sidenav.bg-danger .ps__thumb-y,
.sidenav.bg-danger .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.592329) !important;
}

.sidenav.bg-dark {
  background-color: #2f3337 !important;
  color: #9d9fa1;
}

.sidenav.bg-dark .sidenav-link,
.sidenav.bg-dark .sidenav-horizontal-prev,
.sidenav.bg-dark .sidenav-horizontal-next {
  color: #9d9fa1;
}

.sidenav.bg-dark .sidenav-link:hover, .sidenav.bg-dark .sidenav-link:focus,
.sidenav.bg-dark .sidenav-horizontal-prev:hover,
.sidenav.bg-dark .sidenav-horizontal-prev:focus,
.sidenav.bg-dark .sidenav-horizontal-next:hover,
.sidenav.bg-dark .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-dark .sidenav-link.active,
.sidenav.bg-dark .sidenav-horizontal-prev.active,
.sidenav.bg-dark .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-dark .sidenav-item.disabled .sidenav-link,
.sidenav.bg-dark .sidenav-horizontal-prev.disabled,
.sidenav.bg-dark .sidenav-horizontal-next.disabled {
  color: #717477 !important;
}

.sidenav.bg-dark .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-dark .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-dark .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #393d41;
}

.sidenav.bg-dark.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #43474b;
}

.sidenav.bg-dark.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-dark.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #393d41;
}

.sidenav.bg-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #9d9fa1;
}

.sidenav.bg-dark .sidenav-text {
  color: #fff;
}

.sidenav.bg-dark .sidenav-header {
  color: #828487;
}

.sidenav.bg-dark hr,
.sidenav.bg-dark .sidenav-divider,
.sidenav.bg-dark .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.06) !important;
}

.sidenav.bg-dark .sidenav-inner > .sidenav-header::before,
.sidenav.bg-dark .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.06);
}

.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #45494c;
}

.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #393d41;
}

.sidenav.bg-dark .ps__thumb-y,
.sidenav.bg-dark .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.478839) !important;
}

.sidenav.bg-white {
  background-color: #fff !important;
  color: #a3a4a6;
}

.sidenav.bg-white .sidenav-link,
.sidenav.bg-white .sidenav-horizontal-prev,
.sidenav.bg-white .sidenav-horizontal-next {
  color: #a3a4a6;
}

.sidenav.bg-white .sidenav-link:hover, .sidenav.bg-white .sidenav-link:focus,
.sidenav.bg-white .sidenav-horizontal-prev:hover,
.sidenav.bg-white .sidenav-horizontal-prev:focus,
.sidenav.bg-white .sidenav-horizontal-next:hover,
.sidenav.bg-white .sidenav-horizontal-next:focus {
  color: #4e5155;
}

.sidenav.bg-white .sidenav-link.active,
.sidenav.bg-white .sidenav-horizontal-prev.active,
.sidenav.bg-white .sidenav-horizontal-next.active {
  color: #4e5155;
}

.sidenav.bg-white .sidenav-item.disabled .sidenav-link,
.sidenav.bg-white .sidenav-horizontal-prev.disabled,
.sidenav.bg-white .sidenav-horizontal-next.disabled {
  color: #c8c8ca !important;
}

.sidenav.bg-white .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-white .sidenav-item.active > .sidenav-link {
  color: #4e5155;
}

.sidenav.bg-white .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #fff;
}

.sidenav.bg-white.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #f9f9f9;
}

.sidenav.bg-white.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-white.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #fff;
}

.sidenav.bg-white .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-white .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #a3a4a6;
}

.sidenav.bg-white .sidenav-text {
  color: #4e5155;
}

.sidenav.bg-white .sidenav-header {
  color: #babbbc;
}

.sidenav.bg-white hr,
.sidenav.bg-white .sidenav-divider,
.sidenav.bg-white .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(78, 81, 85, 0.075) !important;
}

.sidenav.bg-white .sidenav-inner > .sidenav-header::before,
.sidenav.bg-white .sidenav-block::before {
  background-color: rgba(78, 81, 85, 0.075);
}

.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #f2f2f2;
}

.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #4e5155;
}

.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #fff;
}

.sidenav.bg-white .ps__thumb-y,
.sidenav.bg-white .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(78, 81, 85, 0.2) !important;
}

.sidenav.bg-light {
  background-color: #f1f1f2 !important;
  color: #a3a4a6;
}

.sidenav.bg-light .sidenav-link,
.sidenav.bg-light .sidenav-horizontal-prev,
.sidenav.bg-light .sidenav-horizontal-next {
  color: #a3a4a6;
}

.sidenav.bg-light .sidenav-link:hover, .sidenav.bg-light .sidenav-link:focus,
.sidenav.bg-light .sidenav-horizontal-prev:hover,
.sidenav.bg-light .sidenav-horizontal-prev:focus,
.sidenav.bg-light .sidenav-horizontal-next:hover,
.sidenav.bg-light .sidenav-horizontal-next:focus {
  color: #4e5155;
}

.sidenav.bg-light .sidenav-link.active,
.sidenav.bg-light .sidenav-horizontal-prev.active,
.sidenav.bg-light .sidenav-horizontal-next.active {
  color: #4e5155;
}

.sidenav.bg-light .sidenav-item.disabled .sidenav-link,
.sidenav.bg-light .sidenav-horizontal-prev.disabled,
.sidenav.bg-light .sidenav-horizontal-next.disabled {
  color: #c2c3c4 !important;
}

.sidenav.bg-light .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-light .sidenav-item.active > .sidenav-link {
  color: #4e5155;
}

.sidenav.bg-light .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #ebebec;
}

.sidenav.bg-light.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #e5e5e6;
}

.sidenav.bg-light.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-light.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #ebebec;
}

.sidenav.bg-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #a3a4a6;
}

.sidenav.bg-light .sidenav-text {
  color: #4e5155;
}

.sidenav.bg-light .sidenav-header {
  color: #b7b7b9;
}

.sidenav.bg-light hr,
.sidenav.bg-light .sidenav-divider,
.sidenav.bg-light .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(78, 81, 85, 0.0777227) !important;
}

.sidenav.bg-light .sidenav-inner > .sidenav-header::before,
.sidenav.bg-light .sidenav-block::before {
  background-color: rgba(78, 81, 85, 0.0777227);
}

.sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #dfdfe0;
}

.sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #4e5155;
}

.sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #ebebec;
}

.sidenav.bg-light .ps__thumb-y,
.sidenav.bg-light .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(78, 81, 85, 0.221782) !important;
}

.sidenav.bg-lighter {
  background-color: #f8f8f8 !important;
  color: #a3a4a6;
}

.sidenav.bg-lighter .sidenav-link,
.sidenav.bg-lighter .sidenav-horizontal-prev,
.sidenav.bg-lighter .sidenav-horizontal-next {
  color: #a3a4a6;
}

.sidenav.bg-lighter .sidenav-link:hover, .sidenav.bg-lighter .sidenav-link:focus,
.sidenav.bg-lighter .sidenav-horizontal-prev:hover,
.sidenav.bg-lighter .sidenav-horizontal-prev:focus,
.sidenav.bg-lighter .sidenav-horizontal-next:hover,
.sidenav.bg-lighter .sidenav-horizontal-next:focus {
  color: #4e5155;
}

.sidenav.bg-lighter .sidenav-link.active,
.sidenav.bg-lighter .sidenav-horizontal-prev.active,
.sidenav.bg-lighter .sidenav-horizontal-next.active {
  color: #4e5155;
}

.sidenav.bg-lighter .sidenav-item.disabled .sidenav-link,
.sidenav.bg-lighter .sidenav-horizontal-prev.disabled,
.sidenav.bg-lighter .sidenav-horizontal-next.disabled {
  color: #c5c6c7 !important;
}

.sidenav.bg-lighter .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-lighter .sidenav-item.active > .sidenav-link {
  color: #4e5155;
}

.sidenav.bg-lighter .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #f2f2f2;
}

.sidenav.bg-lighter.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #ececec;
}

.sidenav.bg-lighter.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-lighter.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #f2f2f2;
}

.sidenav.bg-lighter .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-lighter .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #a3a4a6;
}

.sidenav.bg-lighter .sidenav-text {
  color: #4e5155;
}

.sidenav.bg-lighter .sidenav-header {
  color: #b8b9bb;
}

.sidenav.bg-lighter hr,
.sidenav.bg-lighter .sidenav-divider,
.sidenav.bg-lighter .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(78, 81, 85, 0.0763725) !important;
}

.sidenav.bg-lighter .sidenav-inner > .sidenav-header::before,
.sidenav.bg-lighter .sidenav-block::before {
  background-color: rgba(78, 81, 85, 0.0763725);
}

.sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #e5e6e6;
}

.sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #4e5155;
}

.sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #f2f2f2;
}

.sidenav.bg-lighter .ps__thumb-y,
.sidenav.bg-lighter .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(78, 81, 85, 0.21098) !important;
}

.footer.bg-secondary {
  background-color: #8897aa !important;
  color: #ebeef1;
}

.footer.bg-secondary .footer-link {
  color: #ebeef1;
}

.footer.bg-secondary .footer-link:hover, .footer.bg-secondary .footer-link:focus {
  color: #fff;
}

.footer.bg-secondary .footer-link.disabled {
  color: #c3cbd5 !important;
}

.footer.bg-secondary .footer-text {
  color: #fff;
}

.footer.bg-secondary .show > .footer-link,
.footer.bg-secondary .active > .footer-link,
.footer.bg-secondary .footer-link.show,
.footer.bg-secondary .footer-link.active {
  color: #fff;
}

.footer.bg-secondary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-success {
  background-color: #02bc77 !important;
  color: #cbf1e3;
}

.footer.bg-success .footer-link {
  color: #cbf1e3;
}

.footer.bg-success .footer-link:hover, .footer.bg-success .footer-link:focus {
  color: #fff;
}

.footer.bg-success .footer-link.disabled {
  color: #7bdcb8 !important;
}

.footer.bg-success .footer-text {
  color: #fff;
}

.footer.bg-success .show > .footer-link,
.footer.bg-success .active > .footer-link,
.footer.bg-success .footer-link.show,
.footer.bg-success .footer-link.active {
  color: #fff;
}

.footer.bg-success hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-info {
  background-color: #28c3d7 !important;
  color: #dcf5f8;
}

.footer.bg-info .footer-link {
  color: #dcf5f8;
}

.footer.bg-info .footer-link:hover, .footer.bg-info .footer-link:focus {
  color: #fff;
}

.footer.bg-info .footer-link.disabled {
  color: #94e1eb !important;
}

.footer.bg-info .footer-text {
  color: #fff;
}

.footer.bg-info .show > .footer-link,
.footer.bg-info .active > .footer-link,
.footer.bg-info .footer-link.show,
.footer.bg-info .footer-link.active {
  color: #fff;
}

.footer.bg-info hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-warning {
  background-color: #ffd950 !important;
  color: #998230;
}

.footer.bg-warning .footer-link {
  color: #998230;
}

.footer.bg-warning .footer-link:hover, .footer.bg-warning .footer-link:focus {
  color: #665720;
}

.footer.bg-warning .footer-link.disabled {
  color: #c2a53d !important;
}

.footer.bg-warning .footer-text {
  color: #665720;
}

.footer.bg-warning .show > .footer-link,
.footer.bg-warning .active > .footer-link,
.footer.bg-warning .footer-link.show,
.footer.bg-warning .footer-link.active {
  color: #665720;
}

.footer.bg-warning hr {
  border-color: rgba(102, 87, 32, 0.0832855);
}

.footer.bg-danger {
  background-color: #d9534f !important;
  color: #f7dbda;
}

.footer.bg-danger .footer-link {
  color: #f7dbda;
}

.footer.bg-danger .footer-link:hover, .footer.bg-danger .footer-link:focus {
  color: #fff;
}

.footer.bg-danger .footer-link.disabled {
  color: #eba5a2 !important;
}

.footer.bg-danger .footer-text {
  color: #fff;
}

.footer.bg-danger .show > .footer-link,
.footer.bg-danger .active > .footer-link,
.footer.bg-danger .footer-link.show,
.footer.bg-danger .footer-link.active {
  color: #fff;
}

.footer.bg-danger hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-dark {
  background-color: #2f3337 !important;
  color: #9d9fa1;
}

.footer.bg-dark .footer-link {
  color: #9d9fa1;
}

.footer.bg-dark .footer-link:hover, .footer.bg-dark .footer-link:focus {
  color: #fff;
}

.footer.bg-dark .footer-link.disabled {
  color: #717477 !important;
}

.footer.bg-dark .footer-text {
  color: #fff;
}

.footer.bg-dark .show > .footer-link,
.footer.bg-dark .active > .footer-link,
.footer.bg-dark .footer-link.show,
.footer.bg-dark .footer-link.active {
  color: #fff;
}

.footer.bg-dark hr {
  border-color: rgba(255, 255, 255, 0.06);
}

.footer.bg-white {
  background-color: #fff !important;
  color: #a3a4a6;
}

.footer.bg-white .footer-link {
  color: #a3a4a6;
}

.footer.bg-white .footer-link:hover, .footer.bg-white .footer-link:focus {
  color: #4e5155;
}

.footer.bg-white .footer-link.disabled {
  color: #c8c8ca !important;
}

.footer.bg-white .footer-text {
  color: #4e5155;
}

.footer.bg-white .show > .footer-link,
.footer.bg-white .active > .footer-link,
.footer.bg-white .footer-link.show,
.footer.bg-white .footer-link.active {
  color: #4e5155;
}

.footer.bg-white hr {
  border-color: rgba(78, 81, 85, 0.075);
}

.footer.bg-light {
  background-color: #f1f1f2 !important;
  color: #a3a4a6;
}

.footer.bg-light .footer-link {
  color: #a3a4a6;
}

.footer.bg-light .footer-link:hover, .footer.bg-light .footer-link:focus {
  color: #4e5155;
}

.footer.bg-light .footer-link.disabled {
  color: #c2c3c4 !important;
}

.footer.bg-light .footer-text {
  color: #4e5155;
}

.footer.bg-light .show > .footer-link,
.footer.bg-light .active > .footer-link,
.footer.bg-light .footer-link.show,
.footer.bg-light .footer-link.active {
  color: #4e5155;
}

.footer.bg-light hr {
  border-color: rgba(78, 81, 85, 0.0777227);
}

.footer.bg-lighter {
  background-color: #f8f8f8 !important;
  color: #a3a4a6;
}

.footer.bg-lighter .footer-link {
  color: #a3a4a6;
}

.footer.bg-lighter .footer-link:hover, .footer.bg-lighter .footer-link:focus {
  color: #4e5155;
}

.footer.bg-lighter .footer-link.disabled {
  color: #c5c6c7 !important;
}

.footer.bg-lighter .footer-text {
  color: #4e5155;
}

.footer.bg-lighter .show > .footer-link,
.footer.bg-lighter .active > .footer-link,
.footer.bg-lighter .footer-link.show,
.footer.bg-lighter .footer-link.active {
  color: #4e5155;
}

.footer.bg-lighter hr {
  border-color: rgba(78, 81, 85, 0.0763725);
}

body {
  background: #f5f5f5;
}

.bg-body {
  background: #f5f5f5 !important;
}

.text-primary {
  color: #572D85 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #321a4c !important;
}

.bg-primary {
  background-color: #572D85 !important;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #532b7e !important;
}

.bg-primary-dark {
  background-color: #4e2978 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus {
  background-color: #4a2772 !important;
}

.bg-primary-darker {
  background-color: #4a2671 !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus {
  background-color: #46246b !important;
}

html:not([dir=rtl]) .border-primary, html[dir=rtl] .border-primary {
  border-color: #572D85 !important;
}

.badge-primary {
  background-color: #572D85;
  color: #fff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  background-color: #532b7e;
  color: #fff;
  text-decoration: none;
}

.btn .badge-primary {
  background: #572D85 !important;
  border-color: #572D85 !important;
  color: #fff !important;
}

.badge-outline-primary {
  background-color: transparent;
  box-shadow: 0 0 0 1px #572D85 inset;
  color: #572D85;
}

.badge-outline-primary[href]:hover, .badge-outline-primary[href]:focus {
  color: #572D85;
  text-decoration: none;
}

.btn .badge-outline-primary {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #572D85 inset !important;
  color: #572D85 !important;
}

.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus,
.pagination li.active > a:not(.page-link),
.pagination li.active > a:not(.page-link):hover,
.pagination li.active > a:not(.page-link):focus {
  border-color: #572D85;
  background-color: #572D85;
  color: #fff;
}

.progress-bar {
  background-color: #572D85;
  color: #fff;
}

.list-group-item-primary {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #eeeaf3;
  color: #5f5e61;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #5f5e61;
}

a.list-group-item-primary:hover, a.list-group-item-primary:focus,
button.list-group-item-primary:hover,
button.list-group-item-primary:focus {
  background-color: #ece8f1;
  color: #5f5e61;
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
  border-color: #572D85;
  background-color: #572D85;
  color: #fff;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  border-color: #572D85;
  background-color: #572D85;
  color: #fff;
}

.alert-primary {
  border-color: #d8d3df;
  background-color: #e6e0ed;
  color: #5c5a5f;
}

.alert-primary hr {
  border-top-color: #d8d3df;
}

.alert-primary .alert-link {
  color: #5c5a5f;
}

.alert-dark-primary {
  background-color: #572D85;
  color: #fff;
}

.alert-dark-primary hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.alert-dark-primary .close,
.alert-dark-primary .alert-link {
  color: #fff;
}

.callout-primary {
  border-color: #572D85 !important;
  background-color: #e6e0ed;
  color: #5c5a5f;
}

.callout-primary .callout-link {
  color: #5c5a5f;
}

.tooltip-primary .tooltip-inner, .tooltip-primary > .tooltip .tooltip-inner, .ngb-tooltip-primary + ngb-tooltip-window .tooltip-inner {
  background: #572D85;
  color: #fff;
}

.tooltip-primary.bs-tooltip-top .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-top .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #572D85;
}

.tooltip-primary.bs-tooltip-right .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-right .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #572D85;
}

.tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #572D85;
}

.tooltip-primary.bs-tooltip-left .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-left .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #572D85;
}

.popover-primary, .popover-primary > .popover, .ngb-popover-primary + ngb-popover-window {
  border-color: transparent;
  background: #572D85;
}

.popover-primary .popover-header, .popover-primary > .popover .popover-header, .ngb-popover-primary + ngb-popover-window .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  color: #fff;
}

.popover-primary .popover-body, .popover-primary > .popover .popover-body, .ngb-popover-primary + ngb-popover-window .popover-body {
  background: transparent;
  color: #fff;
}

.popover-primary > .arrow::before, .popover-primary > .popover > .arrow::before, .ngb-popover-primary + ngb-popover-window > .arrow::before {
  border-color: transparent;
}

.popover-primary.bs-popover-top > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="top"] > .arrow::after, .popover-primary > .popover.bs-popover-top > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="top"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-top > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #572D85;
}

.popover-primary.bs-popover-right > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="right"] > .arrow::after, .popover-primary > .popover.bs-popover-right > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="right"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-right > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #572D85;
}

.popover-primary.bs-popover-bottom > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow::after, .popover-primary > .popover.bs-popover-bottom > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="bottom"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-bottom > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #572D85;
}

.popover-primary.bs-popover-left > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="left"] > .arrow::after, .popover-primary > .popover.bs-popover-left > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="left"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-left > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #572D85;
}

.table .table-primary,
.table .table-primary > th,
.table .table-primary > td {
  border-color: rgba(0, 0, 0, 0.035) !important;
  background-color: #eeeaf3;
  color: #5f5e61;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ece8f1;
}

.btn-primary {
  border-color: transparent;
  background: #572D85;
  color: #fff;
}

.btn-primary:hover {
  border-color: transparent;
  background: #532b7e;
  color: #fff;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 2px rgba(87, 45, 133, 0.4);
}

.btn-primary.disabled, .btn-primary:disabled {
  border-color: transparent !important;
  background: #572D85 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-primary .badge {
  background: #fff;
  color: #572D85;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  border-color: transparent;
  background: #4c2774;
  box-shadow: none;
}

.btn-group .btn-primary,
.input-group-prepend .btn-primary,
.input-group-append .btn-primary {
  border-right: 1px solid #4c2774;
  border-left: 1px solid #4c2774;
}

.btn-outline-primary {
  border-color: #572D85;
  background: transparent;
  color: #572D85;
}

.btn-outline-primary:hover {
  border-color: transparent;
  background: #572D85;
  color: #fff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 2px rgba(87, 45, 133, 0.4);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  border-color: #572D85 !important;
  background: transparent !important;
  color: #572D85 !important;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  border-color: transparent;
  background: #532b7e;
  color: #fff;
  box-shadow: none;
}

.btn-outline-primary .badge {
  background: #572D85;
  border-color: #572D85;
  color: #fff;
}

.btn-outline-primary:hover .badge,
.btn-outline-primary:focus .badge,
.btn-outline-primary:active .badge,
.btn-outline-primary.active .badge,
.show > .btn-outline-primary.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #572D85;
}

.dropdown-item:not(.disabled).active,
.dropdown-item:not(.disabled):active {
  background-color: #572D85;
  color: #fff;
}

.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active,
.dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
  background-color: #572D85;
  color: #fff;
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
  background-color: #572D85;
  color: #fff;
}

.tabs-alt.nav-tabs .nav-link.active, .tabs-alt.nav-tabs .nav-link.active:hover, .tabs-alt.nav-tabs .nav-link.active:focus,
.tabs-alt > .nav-tabs .nav-link.active,
.tabs-alt > .nav-tabs .nav-link.active:hover,
.tabs-alt > .nav-tabs .nav-link.active:focus {
  box-shadow: 0 -2px 0 #572D85 inset;
}

.custom-control .custom-control-input:focus ~ .custom-control-label::before,
.custom-control .custom-control-input:active ~ .custom-control-label::before {
  border-color: #572D85;
}

.custom-control .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(87, 45, 133, 0.4);
}

.custom-control.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #572D85;
  background-color: #572D85;
}

.custom-control.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #572D85;
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: inherit;
}

.form-control:focus,
.form-control.focus,
.custom-select:focus {
  border-color: #572D85;
}

.switcher-input:checked ~ .switcher-indicator {
  background: #572D85;
  color: #fff;
}

.switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px rgba(87, 45, 133, 0.4);
}

.switcher-input:active ~ .switcher-indicator {
  box-shadow: none;
}

.navbar.bg-primary {
  background-color: #572D85 !important;
  color: #cec1db;
}

.navbar.bg-primary .navbar-brand {
  color: #fff;
}

.navbar.bg-primary .navbar-brand:hover, .navbar.bg-primary .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-primary .navbar-nav .nav-link {
  color: #cec1db;
}

.navbar.bg-primary .navbar-nav .nav-link:hover, .navbar.bg-primary .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-primary .navbar-nav .nav-link.disabled {
  color: #9e86b9 !important;
}

.navbar.bg-primary .navbar-nav .show > .nav-link,
.navbar.bg-primary .navbar-nav .active > .nav-link,
.navbar.bg-primary .navbar-nav .nav-link.show,
.navbar.bg-primary .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-primary .navbar-toggler {
  color: #cec1db;
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-primary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-primary .navbar-text {
  color: #cec1db;
}

.navbar.bg-primary .navbar-text a {
  color: #fff;
}

.navbar.bg-primary .navbar-text a:hover, .navbar.bg-primary .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-primary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-primary {
  background-color: #572D85 !important;
  color: #cec1db;
}

.sidenav.bg-primary .sidenav-link,
.sidenav.bg-primary .sidenav-horizontal-prev,
.sidenav.bg-primary .sidenav-horizontal-next {
  color: #cec1db;
}

.sidenav.bg-primary .sidenav-link:hover, .sidenav.bg-primary .sidenav-link:focus,
.sidenav.bg-primary .sidenav-horizontal-prev:hover,
.sidenav.bg-primary .sidenav-horizontal-prev:focus,
.sidenav.bg-primary .sidenav-horizontal-next:hover,
.sidenav.bg-primary .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-primary .sidenav-link.active,
.sidenav.bg-primary .sidenav-horizontal-prev.active,
.sidenav.bg-primary .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-primary .sidenav-item.disabled .sidenav-link,
.sidenav.bg-primary .sidenav-horizontal-prev.disabled,
.sidenav.bg-primary .sidenav-horizontal-next.disabled {
  color: #9e86b9 !important;
}

.sidenav.bg-primary .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-primary .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-primary .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #532b7e;
}

.sidenav.bg-primary.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #4f2978;
}

.sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #532b7e;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #cec1db;
}

.sidenav.bg-primary .sidenav-text {
  color: #fff;
}

.sidenav.bg-primary .sidenav-header {
  color: #b09cc6;
}

.sidenav.bg-primary hr,
.sidenav.bg-primary .sidenav-divider,
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-header::before,
.sidenav.bg-primary .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #6d4b91;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #532b7e;
}

.sidenav.bg-primary .ps__thumb-y,
.sidenav.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.506024) !important;
}

.footer.bg-primary {
  background-color: #572D85 !important;
  color: #cec1db;
}

.footer.bg-primary .footer-link {
  color: #cec1db;
}

.footer.bg-primary .footer-link:hover, .footer.bg-primary .footer-link:focus {
  color: #fff;
}

.footer.bg-primary .footer-link.disabled {
  color: #9e86b9 !important;
}

.footer.bg-primary .footer-text {
  color: #fff;
}

.footer.bg-primary .show > .footer-link,
.footer.bg-primary .active > .footer-link,
.footer.bg-primary .footer-link.show,
.footer.bg-primary .footer-link.active {
  color: #fff;
}

.footer.bg-primary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.bg-primary.bs4-toast {
  color: #fff;
  background-color: rgba(87, 45, 133, 0.9) !important;
}

.bg-primary.bs4-toast .toast-header {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.fill-primary {
  fill: #572D85;
}

.sk-primary.sk-plane,
.sk-primary .sk-chase-dot:before,
.sk-primary .sk-bounce-dot,
.sk-primary .sk-wave-rect,
.sk-primary.sk-pulse,
.sk-primary .sk-swing-dot,
.sk-primary .sk-circle-dot:before,
.sk-primary .sk-circle-fade-dot:before,
.sk-primary .sk-grid-cube,
.sk-primary .sk-fold-cube:before {
  background-color: #572D85;
}

.ng-select-focused .ng-select-container {
  border-color: #572D85 !important;
}

.ng-dropdown-panel .ng-option-marked {
  background: #572D85 !important;
  color: #fff !important;
}

.ng-select .ng-spinner-loader {
  border-left-color: #572D85 !important;
}

.ng-select-primary.ng-select-multiple .ng-value,
.ng-select-primary .ng-select-multiple .ng-value {
  background: #572D85 !important;
  color: #fff !important;
}

.ng2-tag-input--focused {
  border-color: #572D85 !important;
}

.ngx-chips-primary[class] .ng2-tag-input tag {
  background-color: #572D85 !important;
  color: #fff !important;
}

.ngx-chips-primary[class] .ng2-tag-input tag:focus, .ngx-chips-primary[class] .ng2-tag-input tag:active {
  background-color: #391e57 !important;
  color: #fff !important;
}

.ngx-chips-primary[class] .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.ngx-chips-primary[class] .ng2-tag-input.ng2-tag-input--disabled tag,
.ngx-chips-primary[class] .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.ngx-chips-primary[class] .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #572D85 !important;
  color: #fff !important;
}

.ui-product-color.active {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #572D85 !important;
}

.bg-navbar-theme {
  background-color: #fff !important;
  color: #a3a4a6;
}

.bg-navbar-theme .navbar-brand {
  color: #4e5155;
}

.bg-navbar-theme .navbar-brand:hover, .bg-navbar-theme .navbar-brand:focus {
  color: #4e5155;
}

.bg-navbar-theme .navbar-nav .nav-link {
  color: #a3a4a6;
}

.bg-navbar-theme .navbar-nav .nav-link:hover, .bg-navbar-theme .navbar-nav .nav-link:focus {
  color: #4e5155;
}

.bg-navbar-theme .navbar-nav .nav-link.disabled {
  color: #c8c8ca !important;
}

.bg-navbar-theme .navbar-nav .show > .nav-link,
.bg-navbar-theme .navbar-nav .active > .nav-link,
.bg-navbar-theme .navbar-nav .nav-link.show,
.bg-navbar-theme .navbar-nav .nav-link.active {
  color: #4e5155;
}

.bg-navbar-theme .navbar-toggler {
  color: #a3a4a6;
  border-color: rgba(78, 81, 85, 0.075);
}

.bg-navbar-theme .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(24, 28, 33, 0.4)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.bg-navbar-theme .navbar-text {
  color: #a3a4a6;
}

.bg-navbar-theme .navbar-text a {
  color: #4e5155;
}

.bg-navbar-theme .navbar-text a:hover, .bg-navbar-theme .navbar-text a:focus {
  color: #4e5155;
}

.bg-navbar-theme hr {
  border-color: rgba(78, 81, 85, 0.075);
}

.layout-navbar {
  box-shadow: 0 1px 0 rgba(24, 28, 33, 0.06);
}

.bg-sidenav-theme {
  background-color: #2e323a !important;
  color: #838790;
}

.bg-sidenav-theme .sidenav-link,
.bg-sidenav-theme .sidenav-horizontal-prev,
.bg-sidenav-theme .sidenav-horizontal-next {
  color: #838790;
}

.bg-sidenav-theme .sidenav-link:hover, .bg-sidenav-theme .sidenav-link:focus,
.bg-sidenav-theme .sidenav-horizontal-prev:hover,
.bg-sidenav-theme .sidenav-horizontal-prev:focus,
.bg-sidenav-theme .sidenav-horizontal-next:hover,
.bg-sidenav-theme .sidenav-horizontal-next:focus {
  color: #fff;
}

.bg-sidenav-theme .sidenav-link.active,
.bg-sidenav-theme .sidenav-horizontal-prev.active,
.bg-sidenav-theme .sidenav-horizontal-next.active {
  color: #fff;
}

.bg-sidenav-theme .sidenav-item.disabled .sidenav-link,
.bg-sidenav-theme .sidenav-horizontal-prev.disabled,
.bg-sidenav-theme .sidenav-horizontal-next.disabled {
  color: #61656e !important;
}

.bg-sidenav-theme .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.bg-sidenav-theme .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #383c44;
}

.bg-sidenav-theme.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #42464d;
}

.bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #383c44;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #838790;
}

.bg-sidenav-theme .sidenav-text {
  color: #fff;
}

.bg-sidenav-theme .sidenav-header {
  color: #6e727b;
}

.bg-sidenav-theme hr,
.bg-sidenav-theme .sidenav-divider,
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.06) !important;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-header::before,
.bg-sidenav-theme .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.06);
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #44484f;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #383c44;
}

.bg-sidenav-theme .ps__thumb-y,
.bg-sidenav-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.477986) !important;
}

.bg-footer-theme {
  background-color: #fff !important;
  color: #a3a4a6;
}

.bg-footer-theme .footer-link {
  color: #a3a4a6;
}

.bg-footer-theme .footer-link:hover, .bg-footer-theme .footer-link:focus {
  color: #4e5155;
}

.bg-footer-theme .footer-link.disabled {
  color: #c8c8ca !important;
}

.bg-footer-theme .footer-text {
  color: #4e5155;
}

.bg-footer-theme .show > .footer-link,
.bg-footer-theme .active > .footer-link,
.bg-footer-theme .footer-link.show,
.bg-footer-theme .footer-link.active {
  color: #4e5155;
}

.bg-footer-theme hr {
  border-color: rgba(78, 81, 85, 0.075);
}
