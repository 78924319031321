.authentication-inner {
  max-width: 400px;
  width: 100%;
}

.authentication-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
}
